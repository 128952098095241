import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import Button from "../Button";
import Account from "./components/Account";
import ConnectWallet from "../ConnectWallet";
import { observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { useCurrentNetworkId } from "../../hooks/useCurrentNetworkId";
import ConnectToNetworkButton from "../ConnectToNetworkButton";

const Header: React.FC = observer(() => {
  // @ts-ignore
  const isMetaMaskInstalled = typeof window.ethereum !== "undefined";
  const { account, chainId } = useEthers();
  const expectedNetworkId = useCurrentNetworkId();

  return (
    <div className={cn(style.root)}>
      <span className={cn(style.title)}>PIPS</span>
      <div className={cn(style.actions)}>
        <NavLink to={"/battles"}>Battles</NavLink>
        <NavLink to={"/rules"} target={"_blank"}>
          Rules
        </NavLink>
        {isMetaMaskInstalled && (
          <React.Fragment>
            <a
              href={"https://www.pipcards.com/mint"}
              target={"_blank"}
              rel="noreferrer"
            >
              <Button>Mint</Button>
            </a>

            {expectedNetworkId !== chainId ? (
              <ConnectToNetworkButton />
            ) : (
              <React.Fragment>
                {account && <Account account={account} />}
                {!account && <ConnectWallet />}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default Header;
