import { computed, makeObservable } from "mobx";
import { TUUID } from "../../../services/api/auth";
import { templates } from "../../../services/logs/templates";
import { IHistoryCard } from "../../../services/api/match";
import { cardHelpers } from "../../../utils/helpers";
import GameController, { TLeadingSuit } from "./GameControllerVM";

export type TMessageAction =
  | "you-lead"
  | "opponent-lead"
  | "you-plays"
  | "opponent-plays"
  | "you-trumps"
  | "opponent-trumps"
  | "you-offsuits"
  | "opponent-offsuits"
  | "you-takes-trick"
  | "opponent-takes-trick"
  | "trick-tie"
  | "you-treasure-win"
  | "opponent-treasure-win"
  | "you-lead-king"
  | "opponent-lead-king"
  | "you-lead-queen"
  | "opponent-lead-queen"
  | "you-lead-jack"
  | "opponent-lead-jack"
  | "you-lead-ace"
  | "opponent-lead-ace"
  | "you-lead-joker"
  | "opponent-lead-joker"
  | "you-end-round"
  | "opponent-end-round"
  | "you-end-game"
  | "opponent-end-game";

export type TMessageLiteral =
  | "ROUND_NUMBER"
  | "TRICK_NUMBER"
  | "PLAYER_PREFIX"
  | "CARD_VALUE"
  | "CARD_SUIT"
  | "NUMBER_OF_HELD_TRICKS"
  | "TRICKS_TAKEN"
  | "P1_SCORE"
  | "P2_SCORE";

export interface TMessageCreateDTO {
  [TMessageLiteral: string]: string | number;
}

export interface ILogMessage {
  title?: string;
  message?: string;
}

export default class LogsModel {
  constructor(controller: GameController) {
    makeObservable(this);
    this.controller = controller;
  }

  readonly controller: GameController;

  @computed
  get logs(): ILogMessage[] {
    let tieCounter: number = 0;

    const logs: ILogMessage[] = [];
    if (!this.controller.getOwner.getUUID) return [];
    if (this.controller.getHistory[0].c.length === 0) {
      const coinTossMsg = this.generateCoinTossMsg(
        this.controller.getPlayerTurnUUID
      );
      logs.push(coinTossMsg);
    }
    let winnerExists: TUUID | undefined;
    this.controller.getHistory.forEach((_) => {
      if (_.r > 3) return;
      const cardsWithJokerExcluded = [..._.c].filter((c) => c.v !== "joker");
      if (logs.length === 0) {
        const coinTossMsg = this.generateCoinTossMsg(_.c[0].uuid);
        logs.push(coinTossMsg);
      }
      if (cardsWithJokerExcluded.length > 1) {
        winnerExists = cardHelpers.definePairWinner(
          cardsWithJokerExcluded,
          this.controller.getOwner.getUUID
        );
      }

      if (cardsWithJokerExcluded.length > 1) tieCounter += winnerExists ? 0 : 1;

      const title = this.generateTitleMsg(_.r, _.t);
      logs.push(title);

      const cardMessages = this.generateCardMsg(_.c);
      logs.push(...cardMessages);

      if (cardsWithJokerExcluded.length > 1) {
        const cardsResult = this.generateTrickResultMsg(
          cardsWithJokerExcluded,
          tieCounter
        );
        logs.push(cardsResult);
      }
      if (winnerExists) tieCounter = 0;
      if (_.t === 13 && _.c.length === 2) {
        const roundResultMsg = this.generateRoundResultMsg(_.r);
        logs.push(roundResultMsg);
      }
      if (_.t === 13 && _.c.length === 2 && _.r === 3) {
        const roundResultMsg = this.generateGameResultMsg();
        logs.push(roundResultMsg);
      }
    });
    return logs;
  }

  protected generateTitleMsg = (round: number, trick: number) => {
    return {
      title: `Round ${round}, Trick ${trick}`,
    };
  };
  protected generateCoinTossMsg = (uuid: TUUID) => {
    return uuid === this.controller.getOwner.getUUID
      ? {
          title: "Your Turn",
          message: "You won the coin toss! \n Select a card to start the game.",
        }
      : {
          title: "Opponent’s Turn",
          message: "Waiting for them to select a card",
        };
  };
  protected generateCardMsg = (cards: IHistoryCard[]): ILogMessage[] => {
    let leadingSuit: TLeadingSuit;
    let msg;
    return cards.reduce((acc: ILogMessage[], card) => {
      const templatePrefix =
        card.uuid === this.controller.getOwner.getUUID ? "you" : "opponent";
      const basicMsg: TMessageCreateDTO = {
        PLAYER_PREFIX:
          templatePrefix.charAt(0).toUpperCase() + templatePrefix.slice(1),
        CARD_VALUE: card.v.charAt(0).toUpperCase() + card.v.slice(1),
        CARD_SUIT: card.s.charAt(0).toUpperCase() + card.s.slice(1),
      };

      const leadingOrderIndex = Math.min(...cards.map((c) => c.o));

      if (card.v !== "joker") {
        msg = this.createMessage(`${templatePrefix}-lead`, basicMsg);

        if (leadingSuit && card.s === leadingSuit)
          msg = this.createMessage(`${templatePrefix}-plays`, basicMsg);

        if (leadingSuit && card.s !== leadingSuit)
          msg = this.createMessage(`${templatePrefix}-offsuits`, basicMsg);

        if (leadingSuit && card.s === "spades")
          msg = this.createMessage(`${templatePrefix}-trumps`, basicMsg);
        if (card.o === leadingOrderIndex) leadingSuit = card.s;
        acc.push({
          message: msg,
        });
      }

      if (cards.length === 1 && card.v === "king") return acc;

      const specialMsg = this.generateSpecialCardMessage(card);
      if (specialMsg) acc.push(specialMsg);
      return acc;
    }, []);
  };
  protected generateTrickResultMsg(
    cards: IHistoryCard[],
    ties: number
  ): ILogMessage {
    const trickWinner = cardHelpers.definePairWinner(
      cards,
      this.controller.getOwner.getUUID
    );
    const NUMBER_OF_HELD_TRICKS = ties;
    const PLAYER_PREFIX =
      trickWinner === this.controller.getOwner.getUUID ? "you" : "opponent";

    if (!trickWinner && ties >= 1)
      return {
        message: this.createMessage("trick-tie", {
          NUMBER_OF_HELD_TRICKS,
        }),
      };

    if (ties > 0)
      return {
        message: this.createMessage(`${PLAYER_PREFIX}-treasure-win`, {
          PLAYER_PREFIX:
            PLAYER_PREFIX.charAt(0).toUpperCase() + PLAYER_PREFIX.slice(1),
          NUMBER_OF_HELD_TRICKS,
        }),
      };

    return {
      message: this.createMessage(`${PLAYER_PREFIX}-takes-trick`, {
        PLAYER_PREFIX:
          PLAYER_PREFIX.charAt(0).toUpperCase() + PLAYER_PREFIX.slice(1),
      }),
    };
  }
  protected generateSpecialCardMessage = (
    card: IHistoryCard
  ): ILogMessage | undefined => {
    const playerPrefix =
      this.controller.getOwner.getUUID === card.uuid ? "you" : "opponent";
    let action: TMessageAction | undefined;
    if (card.v === "joker") action = `${playerPrefix}-lead-joker`;
    if (card.v === "ace") action = `${playerPrefix}-lead-ace`;
    if (card.v === "king") action = `${playerPrefix}-lead-king`;
    if (card.v === "queen") action = `${playerPrefix}-lead-queen`;
    if (card.v === "jack") action = `${playerPrefix}-lead-jack`;
    if (!action) return;
    return {
      message: this.createMessage(action, {}),
    };
  };
  protected generateRoundResultMsg = (round: number) => {
    const yourTricks = cardHelpers.getPlayerTrickScoreByID(
      this.controller.getHistory,
      this.controller.getOwner.getUUID,
      round
    );
    const opponentTricks = cardHelpers.getPlayerTrickScoreByID(
      this.controller.getHistory,
      this.controller.getOpponent.getUUID,
      round
    );
    const playerPrefix = yourTricks > opponentTricks ? "you" : "opponent";
    const points = cardHelpers.calculatePointsByTricks(yourTricks);
    return {
      message: this.createMessage(`${playerPrefix}-end-round`, {
        POINTS: points,
      }),
    };
  };
  protected generateGameResultMsg = () => {
    const ownerPoints = this.controller.ownerPointsScore.reduce(
      (acc, p) => acc + p,
      0
    );
    const opponentPoints = this.controller.ownerPointsScore.reduce(
      (acc, p) => acc + p,
      0
    );
    const playerPrefix = ownerPoints > opponentPoints ? "you" : "opponent";
    return {
      message: this.createMessage(`${playerPrefix}-end-game`, {
        POINTS: ownerPoints,
      }),
    };
  };
  protected createMessage = (
    type: TMessageAction,
    messageDTO: TMessageCreateDTO
  ) => {
    const msgTemplate = templates[type];
    const msgKeys = Object.keys(messageDTO);
    return msgKeys.reduce((acc: string, key: string) => {
      return acc.replace(`[${key}]`, String(messageDTO[key]));
    }, msgTemplate);
  };
}
