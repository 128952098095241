import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Match from "./pages/Match";
import Lobbies from "./pages/Lobbies";
import useStores from "./hooks/useStores";
import Loader from "./components/Loader";
import Guest from "./pages/Guest";
import PageWithHeader from "./layouts/PageWithHeader";
import { useEthers } from "@usedapp/core";
import RulesPage from "./pages/RulesPage";
import Decks from "./pages/Decks";
import { useCurrentNetworkId } from "./hooks/useCurrentNetworkId";
import { getInviteUUID } from "./utils/helpers";

const App: React.FC = () => {
  const rootStore = useStores();
  const { account, chainId } = useEthers();
  const expectedNetworkId = useCurrentNetworkId();

  useEffect(() => {
    const handleSignIn = async (account: string) => {
      await rootStore.authStore.signIn(account);
    };
    if (account && !rootStore.initialized)
      handleSignIn(account).catch(console.error);
  }, [account]);

  const inviteID = getInviteUUID(window.location.search);
  if (inviteID) rootStore.userStore.setInviteUUID(inviteID);
  return (
    <Observer>
      {() =>
        !account || expectedNetworkId !== chainId ? (
          <Router>
            <Routes>
              <Route path="/rules" element={<RulesPage />} />
              <Route
                path="/guest"
                element={
                  <PageWithHeader>
                    <Guest />
                  </PageWithHeader>
                }
              />
              <Route path="*" element={<Navigate to="/guest" replace />} />
            </Routes>
          </Router>
        ) : rootStore.showAppLoading ? (
          <Loader />
        ) : (
          <Router>
            <Routes>
              <Route
                path="/battles"
                element={
                  <PageWithHeader>
                    <Lobbies />
                  </PageWithHeader>
                }
              />
              <Route path="/battles/:id" element={<Match />} />
              <Route
                path="/battles/invite/:id"
                element={
                  <PageWithHeader>
                    <Lobbies />
                  </PageWithHeader>
                }
              />
              <Route path="/rules" element={<RulesPage />} />
              <Route path="/decks" element={<Decks />} />
              <Route path="/" element={<Navigate to="/battles" replace />} />
              <Route
                path="/guest"
                element={<Navigate to="/battles" replace />}
              />
            </Routes>
          </Router>
        )
      }
    </Observer>
  );
};

export default App;
