import { BigNumber } from "ethers";
import { formatEther } from "@ethersproject/units";
import { ICard, IHistoryCard, IHistoryTrick } from "../services/api/match";
import { TUUID } from "../services/api/auth";
import { TCardSuit, TCardValue } from "./convertDeckDictionaryToArray";
import getCardWeightsByType from "./getCardWeight";
import {
  TLeadingSuit,
  TRUMP_SUIT_WEIGHT,
} from "../pages/Match/models/GameControllerVM";

type TSortingType = "asc" | "desc";

export const etc = {
  truncateAccount: (hash: string, length = 38): string =>
    hash.replace(hash.substring(6, length), "..."),
  toFormattedEther: (value: BigNumber): string =>
    (+formatEther(value)).toFixed(4),
  sortBy: (key: string, cb: (a?: any, b?: any) => number) => {
    if (!cb) cb = () => 0;
    return (a: any, b: any) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(a, b);
  },
  sortByDesc: (key: string, cb: (a: any, b: any) => number) => {
    if (!cb) cb = () => 0;
    return (b: any, a: any) =>
      a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : cb(b, a);
  },
  orderBy: (keys: string[], orders: TSortingType[]) => {
    let cb: (a: any, b: any) => number = () => 0;
    keys.reverse();
    orders.reverse();
    // @ts-ignore
    for (const [i, key] of keys.entries()) {
      const order = orders[i];
      if (order === "asc") cb = etc.sortBy(key, cb);
      else if (order === "desc") cb = etc.sortByDesc(key, cb);
      else throw new Error(`Unsupported order "${order}"`);
    }
    return cb;
  },
  replaceIPFSURI: (uri: string) => {
    return uri.replace("ipfs://", "https://assets.pipcards.com/ipfs/");
  },
};

export const cardHelpers = {
  calculateCardWeight: (
    value: TCardValue,
    suit: TCardSuit,
    leadingSuit: TLeadingSuit
  ) => {
    const defaultCardWeight = getCardWeightsByType(`${suit}-${value}`);
    if (!defaultCardWeight) throw new Error(`Card weight cannot be calculated`);
    const inSuitMultiplier = suit === leadingSuit ? 14 : 0;
    const trumpMultiplier = suit === "spades" ? TRUMP_SUIT_WEIGHT : 0;
    return defaultCardWeight + inSuitMultiplier + trumpMultiplier;
  },
  definePairWinner: (
    cards: IHistoryCard[],
    ownerUUID: TUUID
  ): TUUID | undefined => {
    const trickCardsWithoutNonSuit = cards.filter((c) => c.v !== "joker");
    if (trickCardsWithoutNonSuit.length < 2) return undefined;

    const ownerCard = trickCardsWithoutNonSuit.find(
      (c) => c.uuid === ownerUUID
    );
    const opponentCard = trickCardsWithoutNonSuit.find(
      (c) => c.uuid !== ownerUUID
    );
    const leadingOrderIndex = Math.min(
      ...trickCardsWithoutNonSuit.map((c) => c.o)
    );
    const trickLeadingCard = trickCardsWithoutNonSuit.find(
      (c) => c.o === leadingOrderIndex
    );

    if (!ownerCard || !opponentCard)
      throw new Error(
        `Cards amount not equal 2 - current cards amount ${trickCardsWithoutNonSuit.length}`
      );
    if (!trickLeadingCard)
      throw new Error(`Error while defining leading suit for trick`);

    const ownerCardValue = cardHelpers.calculateCardWeight(
      ownerCard.v,
      ownerCard.s,
      trickLeadingCard.s
    );
    const opponentCardValue = cardHelpers.calculateCardWeight(
      opponentCard.v,
      opponentCard.s,
      trickLeadingCard.s
    );
    if (ownerCardValue === opponentCardValue) return undefined;
    return ownerCardValue > opponentCardValue
      ? ownerCard.uuid
      : opponentCard.uuid;
  },
  getPlayerTrickScoreByID: (
    history: IHistoryTrick[],
    uuid: TUUID,
    round: number
  ) => {
    let tieScore = 0;
    const filteredHistory = history.filter((ht) => ht.r === round);
    const score = filteredHistory.reduce((acc, t, index) => {
      if (t.c.length < 2) return acc;

      const winner = cardHelpers.definePairWinner(t.c, uuid);
      if (index + 1 === 13 && !winner) {
        const tieWinner = cardHelpers.getLastTrickTieWinner(
          filteredHistory,
          uuid
        );
        if (uuid === tieWinner) acc += 1;
        return acc;
      }
      if (!winner) tieScore += 1;
      if (winner) {
        if (uuid === winner) {
          // tieScore + current trick win
          acc += tieScore + 1;
          tieScore = 0;
        }
        if (uuid !== winner) tieScore = 0;
      }
      return acc;
    }, 0);
    return score ?? 0;
  },
  getLastTrickTieWinner: (history: IHistoryTrick[], ownerUUID: TUUID) => {
    return history.reverse().reduce((acc: TUUID | undefined, t) => {
      if (acc) return acc;
      const ownerCard = t.c.find((c) => c.uuid === ownerUUID);
      const opponentCard = t.c.find((c) => c.uuid !== ownerUUID);
      if (!ownerCard || !opponentCard) return;
      const own = cardHelpers.definePairWinner(t.c, ownerCard.uuid);
      const opp = cardHelpers.definePairWinner(t.c, opponentCard.uuid);
      if (own) acc = own;
      if (opp) acc = opp;
      return acc;
    }, undefined);
  },
  getRoundTreasurePoints: (history: IHistoryTrick[], round: number) => {
    return history
      .filter((t) => t.r === round)
      .reduce((acc: number, t) => {
        acc = 0;
        const winner = cardHelpers.definePairWinner(t.c, t.c[0].uuid);
        if (!winner) acc += 1;
        return acc;
      }, 0);
  },
  calculatePointsByTricks: (tricks: number) => {
    return tricks < 4
      ? 50
      : tricks === 4
      ? 10
      : tricks === 5
      ? 20
      : tricks === 6
      ? 30
      : tricks < 10 && tricks > 6
      ? 50
      : tricks < 13 && tricks > 9
      ? 0
      : tricks === 13
      ? 75
      : 0;
  },
  knuthShuffle: (cards: ICard[]) => {
    let currentIndex = cards.length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = cards[currentIndex];
      cards[currentIndex] = cards[randomIndex];
      cards[randomIndex] = temporaryValue;
    }

    return cards;
  },
};

export const toFastURI = (ipfs: string): string => {
  if (ipfs && ipfs.match(/^ipfs:\/\//))
    return ipfs.replace("ipfs://", "https://assets.pipcards.com/ipfs/");
  return ipfs;
};
export const getInviteUUID = (searchString: string): string | null => {
  const params = new URLSearchParams(searchString);
  const hasInvite = params.has("invite");
  if (hasInvite) return params.get("invite");
  return null;
};
