import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import DropArea from "./components/DropArea";
import { useMatchVM } from "../../../../MatchVM";
import { observer } from "mobx-react";
import ResultMarker from "./components/ResultMarker/ResultMarker";

const DropZone: React.FC = observer(() => {
  const { controller } = useMatchVM();
  const {
    currentTrickWinnerLabel,
    isDealing,
    opponentPlayedCard,
    ownerPlayedCard,
    showTrickResult,
  } = controller;
  return (
    <div className={cn(style.root)}>
      <DropArea card={ownerPlayedCard} dealing={isDealing} isOwnerZone={true} />
      {showTrickResult && <ResultMarker status={currentTrickWinnerLabel} />}
      <DropArea
        card={opponentPlayedCard}
        dealing={isDealing}
        isOwnerZone={false}
      />
    </div>
  );
});

export default DropZone;
