import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import Session from "./components/Session";
import { observer } from "mobx-react";
import { useLobbiesVM } from "../../LobbiesVM";
import Heading from "./components/Heading";
import Button from "../../../../components/Button";
import ActiveSession from "../ActiveSession";

const Sessions: React.FC = observer(() => {
  const {
    openSelectDeckModal,
    getSessions,
    uuid,
    getLoadingStatus,
    getCurrentSession,
  } = useLobbiesVM();
  return (
    <div className={cn(style.root)}>
      <Heading />
      {getCurrentSession ? (
        <ActiveSession session={getCurrentSession} />
      ) : (
        <div className={cn(style.sessionsList)}>
          {getSessions.length > 0 && uuid ? (
            getSessions.map((s) => (
              <Session key={s.id} data={s} owner_uid={uuid} />
            ))
          ) : getLoadingStatus ? (
            <div className={cn(style.empty, "text title")}>
              Loading Battles...
            </div>
          ) : (
            <div className={cn(style.empty, "text title")}>
              <span className={cn("text title")}>
                There are currently no Battles
              </span>
              <Button onClick={openSelectDeckModal}>New Battle</Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Sessions;
