import React, { useEffect } from "react";
import cn from "classnames";
import style from "./style.module.scss";
import Message from "./components/Message";
import { observer } from "mobx-react";
import { ILogMessage } from "../../../../models/LogsModel";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  messages: ILogMessage[];
}

const MessageBox: React.FC<IProps> = observer(({ messages }) => {
  const endAnchor = React.useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    endAnchor.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  return (
    <div className={cn(style.root)}>
      <div className={cn(style.container)}>
        {messages.length > 0 ? (
          messages.map((msg, index) => <Message message={msg} key={index} />)
        ) : (
          <div className={cn("text message")}>No messages yet!</div>
        )}
        <div ref={endAnchor} />
      </div>
    </div>
  );
});

export default MessageBox;
