import React, { useMemo } from "react";
import style from "./style.module.scss";
import cn from "classnames";
import Modal from "../../../../components/Modal";
import { useAwakenedChampionTokenURIs } from "../../../../hooks/useDeck";
import Button from "../../../../components/Button";
import { useLobbiesVM } from "../../LobbiesVM";
import DecksList from "./components/DecksList";
import { observer } from "mobx-react";

interface IProps {
  selectedDeck: number | null;
  sessionToJoinID: string | undefined;
}

const SelectDeckModal: React.FC<IProps> = observer(
  ({ selectedDeck, sessionToJoinID }) => {
    const { setDeckSelectionModalStatus, createSession, joinSession } =
      useLobbiesVM();
    const tokenURIs = useAwakenedChampionTokenURIs();
    const decks = useMemo(() => tokenURIs.filter((t) => t.id), [tokenURIs]);
    const decksWithTempo =
      process.env.REACT_APP_STATE === "DEV"
        ? [
            ...decks,
            {
              id: 7,
              uri: "https://assets.pipcards.com/ipfs/bafybeibidbjaz5kuk7n4oc2cbro5eboafu6qqo7feodb6l3ejuhz36jwke/cohort-1/7",
            },
          ]
        : decks;
    return (
      <Modal
        className={cn(style.root)}
        modalTitle={<h1>Start New Battle</h1>}
        onClose={() => setDeckSelectionModalStatus(false)}
      >
        <div>
          <h3>Select your Deck to play with</h3>
          <DecksList
            selectedDeckID={selectedDeck}
            decksURIs={decksWithTempo}
          ></DecksList>
          <div className={cn(style.footer)}>
            <Button onClick={() => setDeckSelectionModalStatus(false)}>
              Cancel
            </Button>
            {sessionToJoinID ? (
              <Button
                disabled={!selectedDeck}
                onClick={() => joinSession(sessionToJoinID)}
              >
                Join Battle
              </Button>
            ) : (
              <React.Fragment>
                <Button
                  disabled={!selectedDeck}
                  onClick={() => createSession(false)}
                >
                  Create Battle
                </Button>
                <Button
                  disabled={!selectedDeck}
                  onClick={() => createSession(true)}
                >
                  Create Private Battle
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
    );
  }
);

export default SelectDeckModal;
