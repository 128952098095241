import React, { ChangeEvent, FormEvent } from "react";
import Modal from "../Modal";
import style from "./style.module.scss";
import cn from "classnames";
import { useMatchVM } from "../../pages/Match/MatchVM";
import Button from "../Button";
import { TUUID } from "../../services/api/auth";
import CheckBox from "../CheckBox";
import RadioButton from "../RadioButton";
import Input from "../Input";
import CardsBySuit from "./components/CardsBySuit";
import DuplicatedCards from "./components/DuplicatedCards";
import { observer } from "mobx-react";

interface IProps {}
type TAction = "cards_by_suit" | "duplication";
const DebugModal: React.FC<IProps> = observer(() => {
  const { controller } = useMatchVM();
  const { getOwner, getOpponent } = controller;
  const [action, setAction] = React.useState<TAction>("cards_by_suit");
  const [tricks, setTricks] = React.useState<number>(
    controller.debug.getMaxTricksInRound
  );
  const [uuids, setUUIDs] = React.useState<TUUID[]>(
    controller.debug.getPlayersToAffect
  );

  const checkboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const newUUIDS = uuids.includes(value)
      ? uuids.filter((u) => u !== value)
      : uuids.concat(value);
    setUUIDs(newUUIDS);
    controller.debug.setPlayersToAffect(newUUIDS);
  };

  const handleInput = (number: number) => {
    setTricks(number);
    controller.debug.setMaxTricksInRound(number);
  };

  const handleForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (action === "cards_by_suit") await controller.debug.setHandCardsBySuit();
    if (action === "duplication")
      await controller.debug.setDuplicatedHandCards();
  };

  return (
    <Modal
      modalTitle={"Debug Modal"}
      onClose={() => controller.debug.setDebuggerModalOpened(false)}
    >
      <form className={cn(style.root)} onSubmit={handleForm}>
        <div className={cn("text default", style.title)}>
          Number of tricks for round end
        </div>
        <div className={cn(style.players)}>
          <Input
            value={tricks}
            onChange={(e) => handleInput(+e.currentTarget.value)}
          />
        </div>
        <div className={cn("text default", style.title)}>Other</div>
        <div className={cn(style.players)}>
          <CheckBox
            label={"Show custom suits and weight on card"}
            value={"show_custom_mark"}
            checked={controller.debug.getShowCustomMarks}
            onChange={(e) =>
              controller.debug.setShowCustomMarks(e.currentTarget.checked)
            }
          ></CheckBox>
          <Button
            type="button"
            className={cn(style.defaultBtn)}
            onClick={controller.debug.resetState}
          >
            Refresh State
          </Button>
        </div>
        <div className={cn("text default", style.title)}>Select Player</div>
        <div className={cn(style.players)}>
          <CheckBox
            label={"Owner"}
            value={getOwner.getUUID}
            checked={uuids.includes(getOwner.getUUID)}
            onChange={checkboxHandler}
          ></CheckBox>
          <CheckBox
            label={"Opponent"}
            value={getOpponent.getUUID}
            checked={uuids.includes(getOpponent.getUUID)}
            onChange={checkboxHandler}
          ></CheckBox>
        </div>
        <div className={cn("text default", style.title)}>Select Action</div>
        <div className={cn(style.actions)}>
          <RadioButton
            label={"Generate Hand Cards By Suit"}
            value={action}
            checked={action === "cards_by_suit"}
            onChange={(e) => setAction("cards_by_suit")}
          />
          <RadioButton
            label={"Generate Duplicated Cards"}
            value={action}
            checked={action === "duplication"}
            onChange={(e) => setAction("duplication")}
          />
        </div>
        {action === "cards_by_suit" && <CardsBySuit />}
        {action === "duplication" && <DuplicatedCards />}
        <Button type="submit" className={cn(style.submit)}>
          Apply
        </Button>
      </form>
    </Modal>
  );
});

export default DebugModal;
