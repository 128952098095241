import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  loading?: boolean;
  type?: "button" | "submit";
}

const Button: React.FC<IProps> = ({
  loading,
  type = "button",
  children,
  className,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={cn(style.root, "text regular", className)}
      type={type}
    >
      {loading ? "Loading..." : children}
    </button>
  );
};

export default Button;
