import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { observer } from "mobx-react";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  status: string;
}

const ResultMarker: React.FC<IProps> = observer(({ status, ...rest }) => {
  return (
    <div className={cn(style.root, rest.className, style[status])} {...rest}>
      <div className={cn("text default", style.inner)}>{status}</div>
    </div>
  );
});

export default ResultMarker;
