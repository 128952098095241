import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import {
  TCardSuit,
  TCardValue,
} from "../../../../../../utils/convertDeckDictionaryToArray";

import c from "../../../../../../assets/svg/c.svg";
import d from "../../../../../../assets/svg/d.svg";
import h from "../../../../../../assets/svg/h.svg";
import s from "../../../../../../assets/svg/s.svg";

const suits = {
  hearts: h,
  diamonds: d,
  clubs: c,
  spades: s,
};

interface IProps extends React.HTMLProps<HTMLDivElement> {
  value: TCardValue;
  suit: TCardSuit;
}

const FallbackCardImage: React.FC<IProps> = ({ value, suit, ...rest }) => {
  const shortedValue =
    value.length > 2 ? (value === "joker" ? "jk" : value[0]) : value;

  return (
    <div className={cn(style.root)} {...rest}>
      <div className={cn(style.mark, style.top, style[suit])}>
        <span>{shortedValue}</span>
        {suits[suit] && <img src={suits[suit]} alt={suit} />}
      </div>
      <div className={cn(cn(style.mark, style.bottom, style[suit]))}>
        <span>{shortedValue}</span>
        {suits[suit] && <img src={suits[suit]} alt={suit} />}
      </div>
    </div>
  );
};

export default FallbackCardImage;
