import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { useMatchVM } from "../../MatchVM";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  round: number;
  isRoundResult: boolean;
  isGameResult: boolean;
  points: number[];
}

const GameResult: React.FC<IProps> = ({
  round,
  isRoundResult,
  isGameResult,
  points,
}) => {
  const { controller } = useMatchVM();
  const handleLink = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await controller.leaveGame();
  };

  const resultLabel =
    points[0] > points[1] ? "win" : points[0] === points[1] ? "tie" : "loss";

  return (
    <div className={cn(style.root, resultLabel)}>
      <div className={cn(style.result)}>
        <div className={cn(style.inner)}>
          {isRoundResult && (
            <span className={cn(style.round)}>Round {round}</span>
          )}
          <span className={style.resultLabel}>{resultLabel}</span>
          <span className={cn(style.points)}>
            {points[0]} - {points[1]}
          </span>
          {isGameResult && (
            <a href="/" onClick={handleLink}>
              Go To Lobby
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameResult;
