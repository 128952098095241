import supabase from "./supabase";
import { IPlayerDTO, TUUID } from "./auth";
import convertDeckDictionaryToArray, {
  IDeckDictionary,
  TCardSuit,
  TCardType,
  TCardValue,
} from "../../utils/convertDeckDictionaryToArray";
import { cardHelpers, etc } from "../../utils/helpers";

export interface ICard {
  id: string;
  suit: TCardSuit;
  mark: TCardValue;
  type: TCardType;
  image: string;
  rarityScore?: number;
  weight: number;
}

export interface IMatchDTO {
  id: number;
  created_at: string;
  session_id: number;
  status: TMatchStatus;
  player_turn_uuid: TUUID;
  leading_suit: TCardSuit | null;
  winner_uuid: TUUID | null;
  trick_winner: TUUID[];
  round_number: number;
  trick_number: number;
}

export interface IUpdateMatchDTO {
  player_turn_uuid?: TUUID;
  leading_suit?: TCardSuit | null;
  trick_winner?: TUUID[];
  trick_number?: number;
  round_number?: number;
  status?: TMatchStatus;
  winner_uuid?: TUUID | null;
}

export type TMatchStatus =
  | "waiting_players"
  | "in_progress"
  | "dealing"
  | "showing_trick_result"
  | "showing_round_result"
  | "showing_game_result"
  | "finished";

export const loadDeck = async (uri: string): Promise<IDeckDictionary> => {
  const resp = await fetch(uri);
  const dto = await resp.json();
  return dto.ui.assets.deck;
};

export const createMatchStartWatcher = async (
  session_id: string,
  callback: (session_id: string, match_id: number) => void
) => {
  return supabase
    .from<IMatchDTO>(`match`)
    .on("INSERT", (payload) => callback(session_id, payload.new.id))
    .subscribe();
};

export const createMatchUpdateWatcher = async (
  match_id: number,
  callback: (match: IMatchDTO) => void
) => {
  return supabase
    .from<IMatchDTO>(`match:id=eq.${match_id}`)
    .on("UPDATE", (payload) => callback(payload.new))
    .on("DELETE", () => {
      window.location.href = "/";
    })
    .subscribe();
};

export const getMatchBySessionID = async (session_id: string) => {
  const { data, error } = await supabase
    .from<IMatchDTO>(`match`)
    .select()
    .match({ session_id })
    .single();
  if (error) throw error;
  if (data) return data;
  return data;
};
export const getMatchById = async (match_id: number) => {
  const { data, error } = await supabase
    .from<IMatchDTO>(`match`)
    .select()
    .match({ id: match_id })
    .single();

  if (!data) throw new Error(`Match with id ${match_id} not found`);
  if (error) throw error;
  return data;
};

export const getOpponentPlayerUUID = async (
  current_player_uuid: TUUID,
  match_id: number
) => {
  const { data } = await supabase
    .from("match_users")
    .select()
    .match({ match_id });

  if (!data) throw new Error("Match users not found!");
  if (data.length > 2) throw new Error("To many match users!");

  const opponent_uuid = data.filter(
    (u) => u.player_hash !== current_player_uuid
  )[0].player_hash;

  if (!opponent_uuid) throw new Error("OpponentHandCards user not found!");

  return opponent_uuid;
};

export const updateMatch = async (match_id: number, dto: IUpdateMatchDTO) => {
  return supabase
    .from<IMatchDTO>("match")
    .update(dto, {
      returning: "minimal",
    })
    .match({ id: match_id });
};

export const users = {
  updateHandCards: async (player_hash: TUUID, hand_cards: ICard[]) => {
    await supabase
      .from("match_users")
      .update(
        {
          hand_cards,
        },
        {
          returning: "minimal",
        }
      )
      .match({ player_hash: player_hash });
  },
  setHoveredCard: async (player_hash: TUUID, card_id: string | null) => {
    await supabase
      .from("match_users")
      .update(
        {
          hovered_card: card_id,
        },
        {
          returning: "minimal",
        }
      )
      .match({ player_hash: player_hash });
  },
  updateDeckCards: (player_hash: TUUID, deck_cards: ICard[]) => {
    return supabase
      .from("match_users")
      .update(
        {
          deck_cards,
        },
        {
          returning: "minimal",
        }
      )
      .match({ player_hash: player_hash });
  },
  updateCards: async (
    player_hash: TUUID,
    hand_cards: ICard[],
    deck_cards: ICard[]
  ) => {
    return supabase
      .from("match_users")
      .update(
        {
          hand_cards,
          deck_cards,
        },
        {
          returning: "minimal",
        }
      )
      .match({ player_hash: player_hash });
  },
  getOpponentPlayerData: async () => {
    return await supabase.rpc("get_opponent_cards").single();
  },
  getPlayerData: async (
    player_hash: TUUID,
    match_id: number,
    selectedDeckURI?: string
  ) => {
    const { data, error } = await supabase
      .from<IPlayerDTO>("match_users")
      .select()
      .match({ match_id: match_id, player_hash: player_hash })
      .single();

    if (!data) throw new Error("Player not found");
    if (selectedDeckURI)
      if (!data.deck_cards || !data.hand_cards || !data.cards_back) {
        const playerProfile = await users.initializePlayerDeck(
          match_id,
          player_hash,
          selectedDeckURI
        );
        return playerProfile;
      }
    if (error) throw error;
    return data;
  },
  createPlayerDataUpdateWatcher: async (
    player_uuid: TUUID,
    callback: (data: IPlayerDTO) => void
  ) => {
    return supabase
      .from<IPlayerDTO>(`match_users:player_hash=eq.${player_uuid}`)
      .on("UPDATE", (payload) => callback(payload.new))
      .on("INSERT", (payload) => callback(payload.new))
      .subscribe();
  },
  initializePlayerDeck: async (
    match_id: number,
    player_hash: TUUID,
    selectedDeckURI: string
  ) => {
    const rawDeck = await loadDeck(selectedDeckURI);
    const cardBack = etc.replaceIPFSURI(rawDeck?.back);
    const deck = convertDeckDictionaryToArray(rawDeck);
    const shuffledDeck = cardHelpers.knuthShuffle(deck);
    const handCards = shuffledDeck.slice(0, 13);
    shuffledDeck.splice(0, 13);
    const { data, error } = await supabase
      .from("match_users")
      .update({
        hand_cards: handCards,
        deck_cards: shuffledDeck,
        cards_back: cardBack,
      })
      .match({ match_id: match_id, player_hash: player_hash })
      .single();
    if (!data || error) throw new Error("Error during deck initialization");
    return data;
  },
};
export interface IHistoryTrick {
  id?: number;
  r: number;
  t: number;
  c: IHistoryCard[];
}
export interface IHistoryCard {
  id: string;
  o: number;
  v: TCardValue;
  s: TCardSuit;
  image: string;
  uuid: TUUID;
}

interface IHistoryRecord extends IHistoryTrick {
  match_id: number;
}
export const history = {
  addHistoryTrick: async (match_id: number, trick: IHistoryTrick) => {
    await supabase.from<IHistoryRecord>(`match_history`).insert({
      match_id,
      r: trick.r,
      t: trick.t,
      c: trick.c,
    });
  },
  appendCardToHistoryTricks: async (match_id: number, trick: IHistoryTrick) => {
    await supabase
      .from<IHistoryRecord>(`match_history`)
      .update(
        {
          c: trick.c,
        },
        {
          returning: "minimal",
        }
      )
      .match({
        match_id: match_id,
        t: trick.t,
        r: trick.r,
      });
  },
  getHistoryTricks: async (match_id: number): Promise<IHistoryTrick[]> => {
    const { data, error } = await supabase
      .from<IHistoryRecord>(`match_history`)
      .select()
      .match({ match_id });
    if (!data || error) throw new Error("Failed to fetch match history!");

    return data;
  },
  createHistoryWatcher: async (
    match_id: number,
    insertCallback: (trick: IHistoryTrick) => void,
    updateCallback: (trick: IHistoryTrick) => void
  ) => {
    return supabase
      .from<IHistoryTrick>(`match_history:match_id=eq.${match_id}`)
      .on("INSERT", (payload) => insertCallback(payload.new))
      .on("UPDATE", (payload) => updateCallback(payload.new))
      .subscribe();
  },
};
