import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import DropZone from "./components/DropZone";
import HandCards from "./components/HandCards";
import OpponentHandCards from "./components/OpponentHandCards";
import { observer } from "mobx-react";
import { useMatchVM } from "../../MatchVM";
import RoundRibbon from "../RoundRibbon";

const PlayGroundContainer: React.FC = observer(() => {
  const { controller } = useMatchVM();
  const { cardsModel } = controller;
  return (
    <div className={cn(style.root)}>
      {cardsModel.getPickAbilityState && (
        <div className={cn(style.abilityActiveBackDrop)}></div>
      )}
      <OpponentHandCards />
      <DropZone />
      <HandCards />
      {controller.isShowingRibbon && (
        <RoundRibbon roundNumber={controller.previousRoundNumber} />
      )}
    </div>
  );
});

export default PlayGroundContainer;
