import React, { useEffect } from "react";
import cn from "classnames";
import style from "./style.module.scss";
import CardImage from "../../../CardImage";
import { IHistoryCard } from "../../../../../../../../services/api/match";
import { useMatchVM } from "../../../../../../MatchVM";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  card: IHistoryCard | undefined;
  dealing: boolean;
  isOwnerZone: boolean;
}

const DropArea: React.FC<IProps> = ({ card, dealing, isOwnerZone }) => {
  const { controller } = useMatchVM();
  const { getOwner, getOpponent } = controller;
  const [width, setWidth] = React.useState("200px");
  const [height, setHeight] = React.useState("280px");

  useEffect(() => {
    if (!card) {
      setWidth("200px");
      setHeight("280px");
    } else {
      setWidth("100%");
      setHeight("100%");
    }
  }, [card]);

  return (
    <div
      className={cn(style.root)}
      id={`${isOwnerZone ? "owner" : "opponent"}-dropzone`}
    >
      {card && <CardImage card={card} style={{ width, height }} />}
      {dealing && (
        <React.Fragment>
          <img
            className={cn(style.temp)}
            src={isOwnerZone ? getOpponent.getCardsBack : getOwner.getCardsBack}
            alt="Card"
          />
          <img
            className={cn(
              style.dealingImage,
              isOwnerZone ? "dealingForOwner" : "dealingForOpponent"
            )}
            src={isOwnerZone ? getOpponent.getCardsBack : getOwner.getCardsBack}
            alt="Card"
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default DropArea;
