import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import RoundsRow from "./RoundsRow";
import PointsRow from "./PointsRow";
import ScoreTable from "./ScoreTable";
import { observer } from "mobx-react";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const RoundsLine: React.FC<IProps> = observer(({ ...rest }) => {
  return (
    <div className={cn(style.root, rest.className)} {...rest}>
      <div className={cn(style.rounds)}>
        <table>
          <tbody>
            <RoundsRow />
            <PointsRow />
          </tbody>
        </table>
      </div>
      <ScoreTable />
    </div>
  );
});

export default RoundsLine;
