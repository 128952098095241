import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import roundRibbon from "../../../../assets/svg/turn-ribbon.svg";
import { useMatchVM } from "../../MatchVM";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  roundNumber: number;
}

const RoundRibbon: React.FC<IProps> = ({ roundNumber }) => {
  const [initialOpacity, setInitialOpacity] = React.useState(0);
  const { controller } = useMatchVM();
  setTimeout(() => {
    setInitialOpacity(1);
    setTimeout(() => {
      setInitialOpacity(0);
      setTimeout(() => {
        controller.setRoundRibbonState(false);
      }, 250);
    }, 2000);
  }, 250);

  return (
    <div className={cn(style.root)} style={{ opacity: initialOpacity }}>
      <div
        className={cn(style.ribbon)}
        style={{
          backgroundImage: `url(${roundRibbon})`,
        }}
      >
        <div className={cn(style.round)}>Round {roundNumber}</div>
        <span>Your turn</span>
      </div>
    </div>
  );
};

export default RoundRibbon;
