import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { ICard, IHistoryCard } from "../../../../../../services/api/match";
import FallbackCardImage from "../FallbackCardImage/FallbackCardImage";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  card: IHistoryCard | ICard;
}

const CardImage: React.FC<IProps> = ({ card, ...rest }) => {
  const [loadError, setLoadError] = React.useState<boolean>(false);

  return loadError ? (
    <FallbackCardImage
      value={"v" in card ? card.v : card.mark}
      suit={"s" in card ? card.s : card.suit}
    />
  ) : (
    <div className={cn(style.root)} {...rest}>
      <img
        src={card.image}
        alt={card.id}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          setLoadError(true);
        }}
      />
    </div>
  );
};

export default CardImage;
