import React from "react";
import Header from "../../components/Header";
import style from "./style.module.scss";
import cn from "classnames";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const PageWithHeader: React.FC<IProps> = ({ children }) => {
  return (
    <div className={cn(style.root)}>
      <Header />
      {children}
    </div>
  );
};

export default PageWithHeader;
