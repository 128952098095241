import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  number: number;
}

const RoundMarker: React.FC<IProps> = ({ number, ...rest }) => {
  return (
    <div
      className={cn("text default", style.root, rest.className)}
      {...rest}
      id={`round-${number}`}
    >
      {number}
    </div>
  );
};

export default RoundMarker;
