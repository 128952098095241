import { ICard } from "./match";
import supabase from "./supabase";
import { IDeckDictionary } from "../../utils/convertDeckDictionaryToArray";

export type TUUID = string;

export interface IUserDTO {
  id: TUUID;
  created_at: string;
  selected_deck: number | null;
  active_session_id: string;
  account_hash: string;
}
export interface IUpdateUserDTO {
  selected_deck?: string | null;
  active_session_id: number | null;
}

export interface ICreatePlayerDTO {
  account_hash: string;
}

export interface IPlayerDTO {
  id: TUUID;
  hand_cards: ICard[];
  deck_cards: ICard[];
  cards_back: string;
  hovered_card: string | null;
}

export const getProfile = async (account_hash: string) => {
  return await supabase
    .from<IUserDTO>("users")
    .select()
    .match({ account_hash: account_hash })
    .single();
};
export const createUserUpdateWatcher = async (
  account_hash: string,
  callback: (data: IUserDTO) => void
) => {
  return supabase
    .from<IUserDTO>(`users:account_hash=eq.${account_hash}`)
    .on("UPDATE", (payload) => callback(payload.new))
    .subscribe();
};

export interface IAPIDeck {
  ui: {
    pfp: string;
    deck: IDeckDictionary;
  };
}
export const createProfile = async (account_hash: string) => {
  const createDTO: ICreatePlayerDTO = {
    account_hash: account_hash,
  };

  return await supabase.from<IUserDTO>("users").insert(createDTO).single();
};
