import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { useLobbiesVM } from "../../../../LobbiesVM";
import Button from "../../../../../../components/Button";
import { observer } from "mobx-react";

interface IProps {}

const Heading: React.FC<IProps> = observer(() => {
  const {
    cancelSession,
    leaveSession,
    sessionNotCreated,
    getLoadingStatus,
    amISessionOwner,
    openSelectDeckModal,
    copyInviteLink,
    isCurrentSessionPrivate,
    isLinkCopied,
  } = useLobbiesVM();
  return (
    <div className={cn(style.root)}>
      <span className={cn(style.title, "text title")}>PIPS Battle Lobby</span>
      <Button
        onClick={
          sessionNotCreated
            ? openSelectDeckModal
            : amISessionOwner
            ? cancelSession
            : leaveSession
        }
        loading={getLoadingStatus}
      >
        {sessionNotCreated
          ? "New Battle"
          : amISessionOwner
          ? "Cancel Battle"
          : "Leave Battle"}
      </Button>
      {isCurrentSessionPrivate && amISessionOwner && (
        <Button onClick={copyInviteLink} loading={getLoadingStatus}>
          {isLinkCopied ? "Link Copied!" : "Share Link to Join"}
        </Button>
      )}
    </div>
  );
});

export default Heading;
