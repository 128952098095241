import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

const TreasureEmptyMessage: React.FC = () => {
  return (
    <div className={cn(style.root)}>
      <div className={cn("text message bold")}>Treasure</div>
      <p className={cn("text message")}>
        Winner of the round collects any cards that appear here.
      </p>
    </div>
  );
};

export default TreasureEmptyMessage;
