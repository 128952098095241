export const templates = {
  "you-lead": "You lead with the [CARD_VALUE] of [CARD_SUIT]",
  "opponent-lead": "Opponent leads with the [CARD_VALUE] of [CARD_SUIT]",
  "you-plays": "You played the [CARD_VALUE] of [CARD_SUIT]",
  "opponent-plays": "Opponent plays the [CARD_VALUE] of [CARD_SUIT]",
  "you-trumps": "You trump with the [CARD_VALUE] of [CARD_SUIT]",
  "opponent-trumps": "Opponent trumps with the [CARD_VALUE] of [CARD_SUIT]",
  "you-offsuits": "You played offsuit with the [CARD_VALUE] of [CARD_SUIT]",
  "opponent-offsuits":
    "Opponent plays offsuit with the [CARD_VALUE] of [CARD_SUIT]",
  "you-takes-trick": `You took the trick`,
  "opponent-takes-trick": `Opponent takes the trick`,
  "trick-tie":
    "Trick is a tie, treasure chest increased to [NUMBER_OF_HELD_TRICKS]",
  "you-treasure-win":
    "You won the trick and [NUMBER_OF_HELD_TRICKS] points from the treasure chest",
  "opponent-treasure-win":
    "Opponent wins the trick and [NUMBER_OF_HELD_TRICKS] points from the treasure chest",
  "round-win":
    "[PLAYER_PREFIX] wins the round with a score of [P1_SCORE] to [P2_SCORE]",
  "you-lead-king":
    "When you lead this, if your opponent has a card of this suit, they must play either the Ace of this suit or their highest ranked card of this suit.",
  "opponent-lead-king":
    "The opponent played with a King, so you must play either the Ace of this suit or the highest ranked card of this suit.",
  "you-lead-queen":
    "When you play this - trade one of your cards with your opponent. Choose which card to exchange from your hand and get one of the opponent cards at random.",
  "opponent-lead-queen":
    "The opponent played with a Queen, they will exchange one of their cards to your random card.",
  "you-lead-jack":
    "When you play this, discard any 1 card and replace with the top card of the deck.",
  "opponent-lead-jack":
    "The opponent played with a Jack, wait for them to drop a card.",
  "you-lead-ace":
    "If you play this and lose the trick, you lead the next trick.",
  "opponent-lead-ace":
    "The opponent played with an Ace, if they lose the trick, they will lead the next trick.",
  "you-lead-joker":
    "You played a Joker. Choose one of the 3 cards to either replace a Joker or stash into hand and replace a Joker with another card.",
  "opponent-lead-joker":
    "Opponent played a Joker. Wait for them to choose a card to replace a Joker",
  "you-end-round": "You end round with [POINTS] points.",
  "opponent-end-round": "You end round with [POINTS] points.",
  "you-end-game": "You end the game with [POINTS] points.",
  "opponent-end-game": "You end the game with [POINTS] points.",
};
