import supabase from "./supabase";
import { IUpdateUserDTO, IUserDTO, TUUID } from "./auth";

export type TSessionStatus =
  | "waiting_players"
  | "players_in_session"
  | "ready_to_launch"
  | "in_progress"
  | "finished";

export interface ISession {
  id: string;
  created_at: string;
  owner_hash: string;
  opponent_hash: string;
  status: TSessionStatus;
  private: boolean;
}
export interface IUpdateSession {
  opponent_hash: string | null;
  status: TSessionStatus;
}

export const getSessionsList = async () => {
  const { data, error } = await supabase
    .from<ISession>("sessions")
    .select()
    .match({ private: false });
  if (error || !data) throw error;
  return data;
};

export const getMySession = async (session_id: string) => {
  const { data } = await supabase
    .from<ISession>("sessions")
    .select()
    .match({ id: session_id })
    .single();

  if (!data) throw new Error("No such session");

  return data;
};

export const createSession = async (
  owner_uuid: TUUID,
  status: TSessionStatus = "waiting_players",
  selected_deck: number,
  isPrivate: boolean
) => {
  const { data: session, error } = await supabase
    .from<ISession>("sessions")
    .insert([{ owner_hash: owner_uuid, status, private: isPrivate }])
    .single();

  if (error || !session) throw error;

  await supabase
    .from<IUserDTO>("users")
    .update({ active_session_id: session.id, selected_deck: selected_deck })
    .match({ account_hash: owner_uuid })
    .single();
  return session;
};

export const connectUserToSession = async (
  account_hash: TUUID,
  session_id: string,
  selected_deck: number
) => {
  await supabase
    .from<IUserDTO>("users")
    .update({ active_session_id: session_id, selected_deck: selected_deck })
    .match({ account_hash: account_hash })
    .single();
  const { data: session } = await supabase
    .from<ISession>("sessions")
    .update({ opponent_hash: account_hash, status: "players_in_session" })
    .match({ id: session_id })
    .single();
  return session;
};

export const getUserSelectedDeck = async (
  uuid: TUUID
): Promise<number | null> => {
  const { data } = await supabase
    .from<IUserDTO>("users")
    .select()
    .match({ account_hash: uuid })
    .single();
  if (!data) throw new Error("No such user");
  return data.selected_deck;
};

export const disconnectFromSession = async (
  uuid: TUUID,
  session_id: string
) => {
  await supabase
    .from<IUpdateUserDTO>("users")
    .update({ active_session_id: null, selected_deck: null })
    .match({ account_hash: uuid })
    .single();
  const { data: session } = await supabase
    .from<IUpdateSession>("sessions")
    .update({ opponent_hash: null, status: "waiting_players" })
    .match({ id: session_id })
    .single();
  return session;
};

export const changeSessionStatus = async (
  id: string,
  status: TSessionStatus
) => {
  const { data: session } = await supabase
    .from<ISession>("sessions")
    .update({ status })
    .match({ id })
    .single();
  return session;
};

export const cancelSessionAndDisconnectUser = async (
  session_id: string,
  uuid: TUUID
) => {
  await supabase.from("sessions").delete().match({ id: session_id });
  await supabase
    .from<IUpdateUserDTO>("users")
    .update({ active_session_id: null, selected_deck: null })
    .match({ account_hash: uuid })
    .single();
};

export const createUserJoinWatcher = async (
  session_id: string,
  callback: (data: IUserDTO, session_id: string) => void
) => {
  return supabase
    .from<IUserDTO>(`users:active_session_id=eq.${session_id}`)
    .on("UPDATE", (payload) => callback(payload.new, session_id))
    .subscribe();
};

export const createSessionChangeWatcher = async (
  session_id: string,
  callback: (data: ISession) => void
) => {
  return supabase
    .from<ISession>(`sessions:id=eq.${session_id}`)
    .on("UPDATE", (payload) => callback(payload.new))
    .subscribe();
};
