import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import CardImage from "../../pages/Match/components/PlayGroundContainer/components/CardImage";
import { ICard } from "../../services/api/match";
import AbilityTooltip from "./components/AbilityTooltip";
interface IProps extends React.HTMLProps<HTMLDivElement> {
  card: ICard;
  back?: string;
}

const Card: React.FC<IProps> = ({ card, back, children, ...rest }) => {
  return (
    <div {...rest} className={cn(style.root, rest.className)} id={card.id}>
      <div className={cn("card-inner", style.inner)}>
        <div className={cn(style.front)}>
          <CardImage card={card} />
          <div className={cn(style.controls)}>{children}</div>
          {(card.mark === "joker" ||
            card.mark === "ace" ||
            card.mark === "king" ||
            card.mark === "queen" ||
            card.mark === "jack") && <AbilityTooltip type={card.mark} />}
        </div>
        <div className={cn(style.back)}>
          <img src={back} alt={card.id} />
        </div>
      </div>
    </div>
  );
};

export default Card;
