import * as auth from "./auth";
import * as lobby from "./lobby";
import * as match from "./match";
import supabase from "./supabase";

const api = {
  supabase,
  auth,
  lobby,
  match,
};

export default api;
