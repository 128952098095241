import React from "react";
import style from "./style.module.scss";
import cn from "classnames";
import { useMatchVM } from "../../../../MatchVM";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";

interface IProps {}

const LeaveGameModal: React.FC<IProps> = () => {
  const { controller } = useMatchVM();
  return (
    <Modal
      modalTitle={<h1>Leave Battle</h1>}
      onClose={() => controller.setLeaveModalState(false)}
    >
      <div className={cn(style.root)}>
        <h3>
          Are you sure you want to leave the Battle?
          <br />
          You will not be able to rejoin.
        </h3>
        <Button
          style={{ backgroundColor: "#EB5757" }}
          onClick={() => controller.leaveGame()}
        >
          Yes, Leave Battle
        </Button>
        <div
          className={cn("text default", style.title)}
          onClick={() => controller.setLeaveModalState(false)}
        >
          No, I want to keep playing
        </div>
      </div>
    </Modal>
  );
};

export default LeaveGameModal;
