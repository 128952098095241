import useStores from "../../hooks/useStores";
import useVM from "../../hooks/useVM";
import React, { useMemo } from "react";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { RealtimeSubscription } from "@supabase/supabase-js";
import UserStore from "../../stores/UserStore";
import { getUserSelectedDeck, ISession } from "../../services/api/lobby";
import api from "../../services/api";
import { IUserDTO, TUUID } from "../../services/api/auth";

export default class LobbiesVM {
  private readonly navigator: NavigateFunction;
  private readonly userStore: UserStore;

  constructor(navigator: NavigateFunction, userStore: UserStore) {
    makeObservable(this);
    this.init().then();
    this.navigator = navigator;
    this.userStore = userStore;
  }

  @computed
  get uuid() {
    return this.userStore.getUUID;
  }

  @observable
  protected loading: boolean = true;
  @computed
  get getLoadingStatus() {
    return this.loading;
  }

  @observable
  protected deckSelectionModalOpened: boolean = false;
  @computed
  get isDeckSelectionModelOpened(): boolean {
    return this.deckSelectionModalOpened;
  }
  @action
  setDeckSelectionModalStatus = (status: boolean) => {
    this.deckSelectionModalOpened = status;
  };

  @observable
  protected selectedDeckID: number | null = null;
  @computed
  get getSelectedDeck() {
    return this.selectedDeckID;
  }
  @action
  setSelectedDeckID = (id: number | null) => {
    this.selectedDeckID = id;
  };

  @observable
  currentSession?: ISession;
  @observable
  setCurrentSession = (session: ISession | undefined) => {
    this.currentSession = session;
    if (!session?.opponent_hash) this.opponentSelectedDeckID = null;
  };
  @computed
  get getCurrentSession() {
    return this.currentSession;
  }
  @computed
  get isCurrentSessionPrivate() {
    return this.getCurrentSession?.private;
  }
  @computed
  get sessionNotCreated() {
    return !this.currentSession;
  }
  @computed
  get amISessionOwner() {
    return this.getCurrentSession?.owner_hash === this.uuid;
  }

  @observable
  protected sessions: ISession[] = [];

  @computed
  get getSessions(): ISession[] {
    return this.sessions;
  }

  @observable
  sessionChangeWatcher: RealtimeSubscription | undefined;
  @observable
  matchStartWatcher: RealtimeSubscription | undefined;
  @observable
  userJoinWatcher: RealtimeSubscription | undefined;

  @observable
  protected opponentSelectedDeckID: number | null = null;
  @computed
  get getOpponentSelectedDeckID() {
    return this.opponentSelectedDeckID;
  }
  @observable
  protected ownerSelectedDeckID: number | null = null;
  @computed
  get getOwnerSelectedDeckID() {
    return this.ownerSelectedDeckID;
  }

  @observable
  protected existingMatchId?: number;
  @computed
  get getExistingMatchId() {
    return this.existingMatchId;
  }

  @action
  openSelectDeckModal = () => {
    this.setDeckSelectionModalStatus(true);
    this.setSelectedDeckID(null);
  };

  @action
  createSession = async (isPrivate: boolean) => {
    if (!this.userStore.getUUID || !this.getSelectedDeck || this.loading)
      return;

    this.loading = true;
    const session = await api.lobby.createSession(
      this.userStore.getUUID,
      "waiting_players",
      this.getSelectedDeck,
      isPrivate
    );

    await this.loadAndSetSelectedDeckIDs(
      session.owner_hash,
      session.opponent_hash
    );

    const sessionChangeWatcher = await api.lobby.createSessionChangeWatcher(
      session.id,
      this.setCurrentSession
    );

    const userJoinWatcher = await api.lobby.createUserJoinWatcher(
      session.id,
      this.opponentConnected
    );

    this.matchStartWatcher = await api.match.createMatchStartWatcher(
      session.id,
      this.connectToMatch
    );

    runInAction(() => {
      this.sessionChangeWatcher = sessionChangeWatcher;
      this.userJoinWatcher = userJoinWatcher;
      this.currentSession = session;
      this.loading = false;
      this.setDeckSelectionModalStatus(false);
    });
  };

  @observable
  protected linkCopied: boolean = false;
  @computed
  get isLinkCopied() {
    return this.linkCopied;
  }
  @action
  copyInviteLink = () => {
    if (!this.getCurrentSession) return;
    const textField = document.createElement("textarea");
    const rootLink = window.location.href;
    textField.innerText = `${rootLink}?invite=${this.getCurrentSession.id}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.linkCopied = true;
  };

  @action
  cancelSession = async () => {
    if (!this.userStore.getUUID || !this.currentSession) return;

    this.loading = true;
    await api.lobby.cancelSessionAndDisconnectUser(
      this.currentSession.id,
      this.userStore.getUUID
    );

    runInAction(() => {
      this.currentSession = undefined;
      this.matchStartWatcher = undefined;
      this.sessionChangeWatcher = undefined;
      this.userJoinWatcher = undefined;
      this.existingMatchId = undefined;
      this.loading = false;
      this.setSelectedDeckID(null);
    });
  };
  @observable
  protected sessionToJoinID: string | undefined;
  get getSessionToJoinID() {
    return this.sessionToJoinID;
  }
  @action
  selectSessionForJoin = (sessionID: string) => {
    this.setDeckSelectionModalStatus(true);
    this.sessionToJoinID = sessionID;
  };

  @action
  joinSession = async (sessionID: string) => {
    if (!this.userStore.getUUID || !this.getSelectedDeck) return;
    this.setDeckSelectionModalStatus(false);
    this.loading = true;

    const session = await api.lobby.connectUserToSession(
      this.userStore.getUUID,
      sessionID,
      this.getSelectedDeck
    );
    if (!session) throw new Error("Failed to join session");

    await this.loadAndSetSelectedDeckIDs(
      session.owner_hash,
      session.opponent_hash
    );

    const matchStartWatcher = await api.match.createMatchStartWatcher(
      session.id,
      this.connectToMatch
    );
    runInAction(() => {
      this.currentSession = session;
      this.matchStartWatcher = matchStartWatcher;
      this.loading = false;
    });
  };

  @action
  leaveSession = async () => {
    if (!this.userStore.getUUID) return;
    if (!this.getCurrentSession) return;
    this.loading = true;
    await api.lobby.disconnectFromSession(
      this.userStore.getUUID,
      this.getCurrentSession.id
    );
    runInAction(() => {
      this.currentSession = undefined;
      this.matchStartWatcher = undefined;
      this.userJoinWatcher = undefined;
      this.sessionChangeWatcher = undefined;
      this.loading = false;
    });
  };

  private init = async () => {
    const sessions = await api.lobby.getSessionsList();
    let matchJoinWatcher: RealtimeSubscription | undefined;
    let sessionChangeWatcher: RealtimeSubscription | undefined;
    let userJoinWatcher: RealtimeSubscription | undefined;
    let mySession: ISession | undefined;
    if (this.userStore.getPlayerProfile?.active_session_id) {
      mySession = await api.lobby.getMySession(
        this.userStore.getPlayerProfile.active_session_id
      );
      userJoinWatcher = await api.lobby.createUserJoinWatcher(
        mySession.id,
        this.opponentConnected
      );
      matchJoinWatcher = await api.match.createMatchStartWatcher(
        mySession.id,
        this.connectToMatch
      );
      sessionChangeWatcher = await api.lobby.createSessionChangeWatcher(
        mySession.id,
        this.setCurrentSession
      );
      if (mySession.opponent_hash && mySession.status === "ready_to_launch") {
        const match = await api.match.getMatchBySessionID(mySession.id);
        if (match) this.existingMatchId = match.id;
      }
      await this.loadAndSetSelectedDeckIDs(
        mySession.owner_hash,
        mySession.opponent_hash
      );
    }

    await this.updateSessionList();

    runInAction(() => {
      this.loading = false;
      this.sessions = sessions;
      this.currentSession = mySession;
      this.matchStartWatcher = matchJoinWatcher;
      this.userJoinWatcher = userJoinWatcher;
      this.sessionChangeWatcher = sessionChangeWatcher;
      if (this.userStore.getInviteUUID) {
        this.sessionToJoinID = this.userStore.getInviteUUID;
        this.setDeckSelectionModalStatus(true);
      }
    });
  };

  protected connectToMatch = async (session_id: string, match_id: number) => {
    if (!this.getCurrentSession) return;
    if (session_id === this.getCurrentSession.id)
      this.navigator(`/battles/${match_id}`);
  };

  reconnectToMatch = async () => {
    if (!this.getExistingMatchId) return;
    this.navigator(`/battles/${this.getExistingMatchId}`);
  };

  opponentConnected = async (user: IUserDTO, session_id: string) => {
    if (!user.selected_deck) return;
    this.opponentSelectedDeckID = user.selected_deck;

    /*this spaghetti stays for delay after status update as a timeout
     for match creating
     (because we removed watcher for sessions to prevent sniffing ids)*/
    await setTimeout(async () => {
      await api.lobby.changeSessionStatus(session_id, "ready_to_launch");
    }, 1000);
  };

  @action
  protected updateSessionList = async () => {
    return setInterval(async () => {
      this.sessions = await api.lobby.getSessionsList();
    }, 5000);
  };

  @action
  protected removeSessionFromList = (session: ISession) => {
    this.sessions = this.sessions.filter((_s) => _s.id !== session.id);
    if (this.getCurrentSession && session.id === this.getCurrentSession.id)
      this.currentSession = undefined;
  };

  @action
  protected loadAndSetSelectedDeckIDs = async (
    owner_hash: TUUID,
    opponent_hash: TUUID | null
  ) => {
    let ownerDeckID: number | null = null;
    let opponentDeckID: number | null = null;

    ownerDeckID = await getUserSelectedDeck(owner_hash);
    if (opponent_hash)
      opponentDeckID = await getUserSelectedDeck(opponent_hash);
    runInAction(() => {
      this.opponentSelectedDeckID = opponentDeckID;
      this.ownerSelectedDeckID = ownerDeckID;
    });
  };
}

const ctx = React.createContext<LobbiesVM | null>(null);

// @ts-ignore
export const LobbiesVMProvider: React.FC = ({ children }) => {
  const { userStore } = useStores();
  const navigator = useNavigate();
  const vm = useMemo(
    () => new LobbiesVM(navigator, userStore),
    [navigator, userStore]
  );
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useLobbiesVM = () => useVM(ctx);
