import React from "react";
import style from "./style.module.scss";
import cn from "classnames";
import { useMatchVM } from "../../../../MatchVM";
import Modal from "../../../../../../components/Modal";
import Rule from "../../../../../Guest/components/Rule";
import { NavLink } from "react-router-dom";

interface IProps {}

const RulesModal: React.FC<IProps> = () => {
  const { controller } = useMatchVM();
  return (
    <Modal
      modalTitle={<div className={cn(style.heading)}>Skirmish Cheatsheet</div>}
      style={{ backgroundColor: "#0A0F17" }}
      onClose={() => controller.setRulesModalState(false)}
    >
      <div className={cn(style.root)}>
        <NavLink to={"/rules"} target="_blank" rel="noopener noreferrer">
          Full Rules
        </NavLink>
        <div className={cn(style.content)}>
          <h2>Special Card Abilities </h2>
          <p>
            Non numbered cards (Ace, Jack, Queen, King, Joker) have special{" "}
            <br />
            abilities, which activate when the card is played:
          </p>
          <div className={cn(style.abilities)}>
            <Rule type={"joker"} />
            <Rule type={"ace"} />
            <Rule type={"king"} />
            <Rule type={"queen"} />
            <Rule type={"jack"} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RulesModal;
