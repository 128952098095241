import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import RemainingCards from "./components/RemaningCards";
import MessageBox from "./components/MessageBox";
import { useMatchVM } from "../../MatchVM";
import { observer } from "mobx-react";

const RemainingCardsContainer: React.FC = observer(() => {
  const { controller } = useMatchVM();
  const { getOwner, getOpponent } = controller;
  return (
    <div className={cn(style.root)}>
      <RemainingCards cards={getOpponent.getDeckCards} isOpponent={true} />
      <MessageBox messages={controller.logs.logs} />
      <RemainingCards cards={getOwner.getDeckCards} />
    </div>
  );
});

export default RemainingCardsContainer;
