import React, { useEffect } from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { observer } from "mobx-react";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  isOpponent?: boolean;
  score: number;
}

const PlayerMark: React.FC<IProps> = observer(
  ({ score, isOpponent, ...rest }) => {
    const [offset, setOffset] = React.useState<number>(0);
    useEffect(() => calculateOffset(score), [score]);

    const calculateOffset = (score: number) => {
      const roundEl = document.getElementById(`round-${score}`);
      if (!roundEl) throw new Error("No such score");
      const offset =
        roundEl.getBoundingClientRect().left + roundEl.offsetWidth / 2 - 4;
      setOffset(offset);
    };
    window.addEventListener("resize", () => calculateOffset(score), true);
    return (
      <div
        className={cn(style.root, rest.className, {
          [style.opponent]: isOpponent,
        })}
        style={{
          left: offset,
          ...rest.style,
        }}
        {...rest}
      />
    );
  }
);

export default PlayerMark;
