import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  amount: number;
}
const CardsAmount: React.FC<IProps> = ({ amount }) => {
  return (
    <div className={cn("text small bold", style.root)}>
      {amount} Cards Remaining
    </div>
  );
};

export default CardsAmount;
