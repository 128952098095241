import React from "react";
import Suits from "./components/Suits";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const LeadSuit: React.FC<IProps> = ({ ...rest }) => {
  return (
    <div className={cn(style.root, rest.className)} {...rest}>
      <div className={cn("text default", style.text)}>Lead suit:</div>
      <Suits />
    </div>
  );
};

export default LeadSuit;
