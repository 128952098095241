import { action, computed, makeObservable, runInAction } from "mobx";
import UserStore from "./UserStore";
import AuthStore from "./AuthStore";

export default class RootStore {
  public userStore: UserStore;
  public authStore: AuthStore;
  constructor() {
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this);
    makeObservable(this);
    this.initStores().then();
  }

  @computed
  get initialized() {
    return this.userStore.initialized;
  }

  @computed
  get showAppLoading() {
    return !this.initialized;
  }

  @action
  private initStores = async () => {
    // await Promise.all([])
    //   .then((_) => {
    //     console.log("Application Initialized");
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //     console.log("Application Failed to init");
    //   });
  };
  @action
  private clearStores = () => {
    runInAction(() => {
      this.userStore.initialized = false;
    });
  };
}
