import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { observer } from "mobx-react";
import { useMatchVM } from "../../../../MatchVM";
import Card from "../../../../../../components/OwnerCard";
import { ICard } from "../../../../../../services/api/match";
import { animateRegularCardPlaying } from "../../../../../../utils/animations";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const HandCards: React.FC<IProps> = observer(() => {
  const { controller } = useMatchVM();
  const { cardsModel, getOwner } = controller;

  const isCardCanBePlayedInSuit = (card: ICard) => {
    const anyLeadingSuitCards = !getOwner.getHandCards.find(
      (c) => c.suit === controller.cardsModel.getLeadingSuit
    );
    const isCardInLeadingSuit =
      card.suit === controller.cardsModel.getLeadingSuit ||
      card.suit === "spades" ||
      card.mark === "joker";

    const state = !controller.cardsModel.getLeadingSuit
      ? true
      : isCardInLeadingSuit || anyLeadingSuitCards;
    return state;
  };

  //FIXME: Move abilities checker to utils or AbilitiesVM
  const isJackAbilityActive = () => {
    return (
      cardsModel.ownerPlayedCard &&
      cardsModel.ownerPlayedCard.v === "jack" &&
      cardsModel.getPickAbilityState
    );
  };
  const isQueenAbilityActive = () => {
    return (
      cardsModel.ownerPlayedCard &&
      cardsModel.ownerPlayedCard.v === "queen" &&
      cardsModel.getPickAbilityState
    );
  };
  const isKingAbilityActive = (card: ICard) => {
    const opponentCard = cardsModel.opponentPlayedCard;
    if (!opponentCard) return true;
    if (
      card.mark === "ace" &&
      card.suit === opponentCard.s &&
      opponentCard.v === "king"
    )
      return true;
    if (card.mark === "joker" && opponentCard.v === "king") return true;
    const biggestCardOfSuit = getOwner.getHandCards
      .filter((c) => c.suit === opponentCard.s)
      .reduce((acc: ICard | null, c) => {
        if (acc && acc.weight <= c.weight) {
          acc = c;
        }
        if (!acc) acc = c;
        return acc;
      }, null);

    if (opponentCard.v === "king" && biggestCardOfSuit) {
      return (
        biggestCardOfSuit.suit === card.suit &&
        card.weight >= biggestCardOfSuit?.weight
      );
    } else {
      return true;
    }
  };
  const isCardCanBePlayed = (card: ICard) => {
    return controller.isOwnerTurn && isCardCanBePlayedInSuit(card);
  };

  const checkCard = (card: ICard) => {
    return (
      isKingAbilityActive(card) &&
      isCardCanBePlayed(card) &&
      !cardsModel.getPickAbilityState
    );
  };

  const regularCardPlayHandler = async (card: ICard) => {
    await animateRegularCardPlaying(card, controller);
  };

  return (
    <TransitionGroup
      id={"owner-hand"}
      className={cn(style.root, {
        [style.abilityActive]: cardsModel.getPickAbilityState,
        [style.dealt]:
          !controller.isDealing &&
          controller.getStatus !== "showing_round_result",
      })}
    >
      {getOwner.getHandCards.map((card, index) => (
        <CSSTransition key={card.id} timeout={700} classNames={cn("card")}>
          <Card
            card={card}
            key={card.id}
            back={getOwner.getCardsBack}
            className={cn("card", {
              animated: card.id !== cardsModel.getUsedCard?.id,
            })}
          >
            {controller.isCardCanBePlayed &&
              card.id !== cardsModel.getUsedCard?.id && (
                <React.Fragment>
                  {checkCard(card) && !cardsModel.ownerPlayedCard && (
                    <button
                      className={cn("text regular")}
                      onClick={() => regularCardPlayHandler(card)}
                    >
                      Play Card
                    </button>
                  )}
                  {isJackAbilityActive() && (
                    <button
                      className={cn("text regular")}
                      onClick={() => cardsModel.useJackAbility(card)}
                    >
                      Drop Card
                    </button>
                  )}
                  {isQueenAbilityActive() && (
                    <button
                      className={cn("text regular")}
                      onClick={() => cardsModel.useQueenAbility(card)}
                    >
                      Exchange Card
                    </button>
                  )}
                </React.Fragment>
              )}
          </Card>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
});

export default HandCards;
