import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

import fullScreen from "../../../../../../assets/svg/fullscreen.svg";
import exit from "../../../../../../assets/svg/exit.svg";
import { useMatchVM } from "../../../../MatchVM";

const TreasureControls: React.FC = () => {
  const { controller } = useMatchVM();
  const fullScreenHandler = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  return (
    <div className={cn(style.root)}>
      <button
        className={cn("text regular", style.button)}
        onClick={() => controller.setRulesModalState(true)}
      >
        Rules
      </button>
      {process.env.REACT_APP_STATE === "DEV" && (
        <button
          className={cn(style.button, style.qa)}
          onClick={() => controller.debug.setDebuggerModalOpened(true)}
        >
          QA
        </button>
      )}
      <button
        className={cn(style.button, style.leave)}
        onClick={() => controller.setLeaveModalState(true)}
      >
        <img src={exit} alt="Exit" />
      </button>
      <button
        className={cn(style.button, style.fullscreen)}
        onClick={fullScreenHandler}
      >
        <img src={fullScreen} alt="Full screen" />
      </button>
    </div>
  );
};

export default TreasureControls;
