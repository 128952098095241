import React, { FormEvent } from "react";
import cn from "classnames";
import style from "./style.module.scss";
import Input from "../../../Input";
import Select from "../../../Select";
import Button from "../../../Button";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import { observer } from "mobx-react";
import { useMatchVM } from "../../../../pages/Match/MatchVM";

interface IProps {}

const CARD_VALUES = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "jack",
  "queen",
  "king",
  "ace",
  "joker",
];
const CARD_SUITS = ["spades", "hearts", "diamonds", "clubs"];

export interface IDebugDuplicationCard {
  id: string;
  value: string;
  suit: string;
  duplicates_amount: number;
}

const DuplicatedCards: React.FC<IProps> = observer(({ ...rest }) => {
  const { controller } = useMatchVM();
  const basicCard = {
    id: uuid(),
    value: "3",
    suit: "h",
    duplicates_amount: 1,
  };
  const values = CARD_VALUES;
  const suits = CARD_SUITS;
  const [cards, setCards] = React.useState<IDebugDuplicationCard[]>(
    controller.debug.getCardsForDuplication
  );

  const addCardHandler = () => {
    const newCards = cards.concat(basicCard);
    setCards(newCards);
    controller.debug.setCardsForDuplication(newCards);
  };

  const removeCardHandler = (card: IDebugDuplicationCard) => {
    const newCards = cards.filter((c) => c.id !== card.id);
    setCards(newCards);
    controller.debug.setCardsForDuplication(newCards);
  };

  const handleCardValueChange = (
    cardId: string,
    e: FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const card = cards.find((c) => c.id === cardId);
    if (!card) throw new Error("Card not found");
    const name = e.currentTarget.name as "value" | "suit" | "duplicates_amount";
    // @ts-ignore
    card[name] = e.currentTarget.value;
    const newCards = cards.map((c) => (c.id === cardId ? card : c));
    setCards(newCards);
    controller.debug.setCardsForDuplication(newCards);
  };

  return (
    <div className={cn(style.root)}>
      <div className={cn("text default", style.title)}>
        Select Card and Suit
      </div>
      <div>
        {cards.map((c) => (
          <div className={cn(style.card)}>
            <Select
              name={"value"}
              value={c.value}
              options={values}
              onChange={(e) => handleCardValueChange(c.id, e)}
            />
            <Select
              name={"suit"}
              value={c.suit}
              options={suits}
              onChange={(e) => handleCardValueChange(c.id, e)}
            />
            <Input
              name={"duplicates_amount"}
              placeholder="Amount"
              value={c.duplicates_amount}
              onChange={(e) => handleCardValueChange(c.id, e)}
            />
            <Button onClick={() => removeCardHandler(c)}>Remove</Button>
          </div>
        ))}
      </div>

      <Button onClick={addCardHandler}>Add Card</Button>
    </div>
  );
});

export default DuplicatedCards;
