import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import RoundsLine from "./RoundsLine";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const ScoreTracker: React.FC<IProps> = ({ ...rest }) => {
  return (
    <div className={cn(style.root, rest.className)} {...rest}>
      <RoundsLine />
    </div>
  );
};

export default ScoreTracker;
