import { action, computed, makeObservable, observable } from "mobx";
import convertDeckDictionaryToArray, {
  TCardSuit,
  TCardType,
} from "../../../utils/convertDeckDictionaryToArray";
import GameController from "./GameControllerVM";
import api from "../../../services/api";
import { IAPIDeck, TUUID } from "../../../services/api/auth";
import { IDebugDuplicationCard } from "../../../components/DebugModal/components/DuplicatedCards/DuplicatedCards";
import { ICard } from "../../../services/api/match";

export default class DebugVM {
  constructor(GC: GameController) {
    makeObservable(this);
    this.GC = GC;
  }

  protected GC: GameController;

  @observable
  debuggerModalOpened: boolean = false;
  @computed
  get getDebuggerModalOpened(): boolean {
    return this.debuggerModalOpened;
  }
  @action
  setDebuggerModalOpened(state: boolean) {
    this.debuggerModalOpened = state;
  }

  @action
  setMaxTricksInRound(amount: number) {
    this.GC.setMaxTricksInRound(amount);
  }
  @computed
  get getMaxTricksInRound(): number {
    return this.GC.getMaxTricksInRound;
  }

  @observable
  protected selectedSuit: TCardSuit = "clubs";
  @computed
  get getSelectedSuit(): TCardSuit {
    return this.selectedSuit;
  }
  @action
  setSelectedSuit(suit: TCardSuit) {
    this.selectedSuit = suit;
  }

  @observable
  showCustomMarks: boolean = false;
  @computed
  get getShowCustomMarks(): boolean {
    return this.showCustomMarks;
  }
  @action
  setShowCustomMarks(state: boolean) {
    this.showCustomMarks = state;
  }

  @observable
  protected playersToAffect: TUUID[] = [];
  get getPlayersToAffect(): TUUID[] {
    return this.playersToAffect;
  }
  @action
  setPlayersToAffect(playersUUIDs: TUUID[]) {
    this.playersToAffect = playersUUIDs;
  }

  @action
  setHandCardsBySuit = async () => {
    const data: IAPIDeck = await fetch(
      "https://assets.pipcards.com/ipfs/QmPa2BnfitLCgVbnqQF2L1mAWdfwBnq17nhymcsNYjJeua",
      {
        method: "GET",
      }
    ).then(async (res) => {
      return await res.json();
    });
    const convertedCards = convertDeckDictionaryToArray(data.ui.deck);
    const cardsBySuit = convertedCards.filter(
      (c) => c.suit === this.getSelectedSuit
    );
    if (this.getPlayersToAffect.length > 0)
      await Promise.all(
        this.getPlayersToAffect.map((uuid) => {
          api.match.users.updateHandCards(uuid, cardsBySuit);
        })
      );
  };

  @observable
  protected cardsForDuplication: IDebugDuplicationCard[] = [];
  get getCardsForDuplication(): IDebugDuplicationCard[] {
    return this.cardsForDuplication;
  }
  @action
  setCardsForDuplication(cards: IDebugDuplicationCard[]) {
    this.cardsForDuplication = cards;
  }

  @action
  setDuplicatedHandCards = async () => {
    const data: IAPIDeck = await fetch(
      "https://assets.pipcards.com/ipfs/QmPa2BnfitLCgVbnqQF2L1mAWdfwBnq17nhymcsNYjJeua",
      {
        method: "GET",
      }
    ).then(async (res) => {
      return await res.json();
    });
    const convertedCards = convertDeckDictionaryToArray(data.ui.deck);

    const newHandCards = this.getCardsForDuplication.reduce(
      (acc: ICard[], card) => {
        const duplicatedArray: ICard[] = [];
        for (let i = 1; i <= card.duplicates_amount; i++) {
          const cardFromAssets = convertedCards.find(
            (c) => c.type === (`${card.value}-${card.suit}` as TCardType)
          );
          if (cardFromAssets) duplicatedArray.push(cardFromAssets);
        }
        acc = acc.concat(...duplicatedArray);
        return acc;
      },
      []
    );
    if (this.getPlayersToAffect.length > 0)
      await Promise.all(
        this.getPlayersToAffect.map((uuid) => {
          api.match.users.updateHandCards(uuid, newHandCards);
        })
      );
  };

  @action
  resetState = async () => {
    await api.match.updateMatch(this.GC.getMatchId, {
      player_turn_uuid: this.GC.getOwner.getUUID,
      leading_suit: null,
      trick_winner: [],
      trick_number: 0,
      round_number: 0,
      status: "in_progress",
      winner_uuid: null,
    });
  };
}
