import Artifact from "../configs/artifacts/AwakenedChampion.sol/AwakenedChampion.json";
import {
  ContractArgs,
  singleContractMultipleCalls,
  toInterface,
  useContract,
} from "./useContract";
import { AwakenedChampion } from "../configs/typechain/AwakenedChampion";
import { BigNumber, ethers } from "ethers";
import {
  safelyUnwrapBNResponse,
  toSafeAccount,
} from "../utils/contractHelpers";
import contractConfig from "../configs";
import { useMemo } from "react";
import { useCurrentNetworkId } from "./useCurrentNetworkId";
import { useContractCall, useContractFunction, useEthers } from "@usedapp/core";
import { toFastURI } from "../utils/helpers";

export interface NftInfo {
  id: number;
  uri: string;
}

export default function useAwakenedChampion() {
  return useContract<AwakenedChampion>("awakenedChampion", Artifact.abi);
}

const useAwakenedChampionAddress = (): string => {
  const currentNetworkChainId = useCurrentNetworkId();
  return contractConfig[currentNetworkChainId].awakenedChampion || "";
};

export function useAwakenedChampionContract(): AwakenedChampion {
  const currentNetworkChainId = useCurrentNetworkId();
  const address = contractConfig[currentNetworkChainId].awakenedChampion || "";

  return useMemo(() => {
    return new ethers.Contract(address, Artifact.abi) as AwakenedChampion;
  }, [address]);
}

export const useAwakenedChampionMintFunc = () => {
  const contract = useAwakenedChampionContract();
  //@ts-ignore
  return useContractFunction(contract, "mintChampion", {
    transactionName: "mintChampion",
  });
};

const AwakenedChampionInterface = toInterface(Artifact.abi);

export const useAwakenedChampionWithMultipleCalls = singleContractMultipleCalls(
  AwakenedChampionInterface,
  "awakenedChampion"
);

type AwakenedChampionData = {
  owner: string;
  accountBalance: BigNumber;
  maxCount: BigNumber;
  maxCountPerWallet: BigNumber;
  mintFee: BigNumber;
  paused: boolean;
  totalSupply: BigNumber;
  isInitialized: boolean;
};

export const useAwakenedChampionData = (
  account: string | undefined | null
): AwakenedChampionData => {
  const [
    owner,
    totalSupply,
    maxCount,
    maxCountPerWallet,
    mintFee,
    accountBalance,
    paused,
    isInitialized,
  ] = useAwakenedChampionWithMultipleCalls([
    { method: "owner" },
    { method: "totalSupply" },
    { method: "maxCount" },
    { method: "maxCountPerWallet" },
    { method: "mintFee" },
    { method: "balanceOf", args: [toSafeAccount(account)] },
    { method: "paused" },
    { method: "isInitialized" },
  ]);

  return {
    owner: owner ? owner[0] : "",
    accountBalance: safelyUnwrapBNResponse(accountBalance),
    maxCount: safelyUnwrapBNResponse(maxCount),
    maxCountPerWallet: safelyUnwrapBNResponse(maxCountPerWallet),
    mintFee: safelyUnwrapBNResponse(mintFee),
    paused: paused?.[0],
    totalSupply: safelyUnwrapBNResponse(totalSupply),
    isInitialized: isInitialized?.[0],
  };
};

export const useAccountTokenIDs = (): BigNumber[] => {
  const { account } = useEthers();
  const { accountBalance } = useAwakenedChampionData(account);

  const contractCalls = useMemo(() => {
    const calls: ContractArgs[] = [];
    for (let i = 0; i < accountBalance.toNumber(); i += 1) {
      calls.push({ method: "tokenOfOwnerByIndex", args: [account, i] });
    }
    return calls;
  }, [account, accountBalance]);

  return useAwakenedChampionWithMultipleCalls(contractCalls)
    .map((result: any) => {
      return safelyUnwrapBNResponse(result);
    })
    .filter((i: any) => i.gt(0));
};

export const useAwakenedChampionTokenURI = (id: number): string => {
  const address = useAwakenedChampionAddress();
  const uri = useContractCall({
    abi: AwakenedChampionInterface,
    address,
    method: "tokenURI",
    args: [id],
  });

  return toFastURI(uri ? uri[0] : "")
};

export const useAwakenedChampionTokenURIs = (): NftInfo[] => {
  const ids = useAccountTokenIDs();
  const contractCalls = useMemo(
    () =>
      ids.map((id) => {
        return {
          method: "tokenURI",
          args: [id],
        };
      }),
    [ids]
  );

  return useAwakenedChampionWithMultipleCalls(contractCalls)
    .map((result: any, i: any) => ({
      id: ids[i].toNumber(),
      uri: toFastURI(result ? result[0] : ""),
    }))
    .filter((i: any) => i.uri);
};

export const useAwakenedChampionCohortLength = (cohortId: number): number => {
  const address = useAwakenedChampionAddress();
  const uri = useContractCall({
    abi: AwakenedChampionInterface,
    address,
    method: "cohortLength",
    args: [cohortId],
  });

  return safelyUnwrapBNResponse(uri).toNumber();
};

export const useAwakenedChampionCohortIds = (cohortId: number): number[] => {
  const cohortLength = useAwakenedChampionCohortLength(cohortId);
  const contractCalls = useMemo(() => {
    const calls = [];
    for (let id = 0; id < cohortLength; id++) {
      calls.push({
        method: "cohortId",
        args: [cohortId, id],
      });
    }
    return calls;
  }, [cohortId, cohortLength]);

  const results = useAwakenedChampionWithMultipleCalls(contractCalls);

  return useMemo(
    () => results.map((result: any) => result?.[0] || 0),
    [results]
  );
};

type GenesisSeedData = {
  id: number;
  genesisSeed: string;
};

export const useAwakenedChampionCohortGenesisSeeds = (
  cohortId: number
): GenesisSeedData[] => {
  const ids = useAwakenedChampionCohortIds(cohortId);
  const contractCalls = useMemo(
    () =>
      ids.map((id) => ({
        method: "genesisSeeds(uint256)",
        args: [id],
      })),
    [ids]
  );

  const data = useAwakenedChampionWithMultipleCalls(contractCalls);

  return useMemo(
    () =>
      data.map((result: any, i: any) => ({
        id: BigNumber.from(ids?.[i] || 0).toNumber(),
        genesisSeed: result ? result[0] : "not-found",
      })),
    [data, ids]
  );
};
