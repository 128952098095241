import { ICard } from "../services/api/match";
import GameController from "../pages/Match/models/GameControllerVM";

const animateRegularCardPlaying = async (
  card: ICard,
  controller: GameController
) => {
  const cardWithOutRef = Object.assign(card, {});
  const myDropZone = document.getElementById("owner-dropzone");
  const selectedCard = document.getElementById(cardWithOutRef.id);

  if (
    !myDropZone ||
    !selectedCard ||
    controller.cardsModel.isHandlingAbilityRequest
  )
    return;
  animateCardToDropZone(myDropZone, selectedCard, true, true, false);
  await controller.cardsModel.playRegularCard(cardWithOutRef);
};

// Non Usable until hand rework
// const animateQueenAbility = (card: ICard, controller: GameController) => {
//   const queenCards = controller.cardsModel.useQueenAbility(card);
//
//   if (!queenCards) return;
//   const { ownerCard, opponentCard } = queenCards;
//
//   const ownerDOMCard = document.getElementById(ownerCard.id);
//   const opponentDOMCard = document.getElementById(opponentCard.id);
//
//   const ownerDropZone = document.getElementById("owner-dropzone");
//   const opponentDropZone = document.getElementById("opponent-dropzone");
//
//   if (!ownerDOMCard || !opponentDOMCard || !ownerDropZone || !opponentDropZone)
//     return;
//
//   const ownerCardWithAnimated = animateCardToDropZone(
//     ownerDropZone,
//     ownerDOMCard,
//     false,
//     true,
//     true
//   );
//   const opponentCardWithAnimated = animateCardToDropZone(
//     opponentDropZone,
//     opponentDOMCard,
//     false,
//     false,
//     true
//   );
//
//   const ownerCardInner = ownerDOMCard.getElementsByClassName("card-inner")[0];
//   const opponentCardInner =
//     opponentDOMCard.getElementsByClassName("card-inner")[0];
//   if (!ownerCardInner || !opponentCardInner) return;
//   //@ts-ignore
//   opponentCardInner.style.transform = "rotateY(180deg)";
//   setTimeout(() => {
//     //@ts-ignore
//     ownerCardInner.style.transform = "rotateY(180deg)";
//   }, 1000);
//   setTimeout(() => {
//     ownerCardWithAnimated.element.style.transform = `translate(${ownerCardWithAnimated.translate.x}px, -200vh)`;
//     opponentCardWithAnimated.element.style.transform = `translate(${ownerCardWithAnimated.translate.x}px, 200vh)`;
//   }, 1000);
// };

const animateCardToDropZone = (
  zone: HTMLElement,
  card: HTMLElement,
  useScale: boolean,
  forOwner: boolean,
  centerCard: boolean
) => {
  let newY = 0;
  let newX = 0;

  const isHovered = card.matches(":hover");
  const hoverModule = isHovered ? 205 : 0;

  card.classList.add("disableAbilityTooltips");
  card.style.border = "0";

  const dropZoneRect = zone.getBoundingClientRect();
  const selectedCardRect = card.getBoundingClientRect();

  const widthScaleFactor = dropZoneRect.width / (selectedCardRect.width + 2);
  const heightScaleFactor = dropZoneRect.height / (selectedCardRect.height + 2);

  if (forOwner) {
    newY = (selectedCardRect.y - dropZoneRect.y + hoverModule - 2) * -1;
    newX = (selectedCardRect.x - dropZoneRect.x - 1) * -1;
  } else {
    newY = dropZoneRect.y - selectedCardRect.y;
    newX = dropZoneRect.x - selectedCardRect.x;
  }

  if (centerCard) {
    const centeringX = (dropZoneRect.width - selectedCardRect.width) / 2;
    const centeringY = (dropZoneRect.height - selectedCardRect.height) / 2;
    newY += centeringY;
    newX += centeringX;
  }

  const flooredWidthScale = Math.round(widthScaleFactor * 100) / 100;
  const flooredHeightScale = Math.round(heightScaleFactor * 100) / 100;

  const flooredX = Math.floor(newX);
  const flooredY = Math.floor(newY);

  card.style.transform = `translate(${flooredX}px, ${flooredY}px) ${
    useScale ? `scale(${flooredWidthScale}, ${flooredHeightScale})` : ""
  }`;
  card.style.zIndex = "-1";

  return {
    element: card,
    translate: {
      x: flooredX,
      y: flooredY,
    },
  };
};

export { animateRegularCardPlaying, animateCardToDropZone };
