import React from "react";
import Header from "./components/Header";
import cn from "classnames";
import style from "./style.module.scss";
import RemainingCardsContainer from "./components/RemainingCardsContainer";
import TreasureContainer from "./components/TreasureContainer";
import PlayGroundContainer from "./components/PlayGroundContainer";
import { MatchVMProvider, useMatchVM } from "./MatchVM";
import { observer } from "mobx-react";
import Loader from "../../components/Loader";
import GameResult from "./components/GameResult";
import JokerAbility from "./components/Abilities/JokerAbility";
import DebugModal from "../../components/DebugModal";
import LeaveGameModal from "./components/PlayGroundContainer/components/LeaveGameModal";
import RulesModal from "./components/PlayGroundContainer/components/RulesModal";
import RoundRibbon from "./components/RoundRibbon/RoundRibbon";

const Match: React.FC = () => (
  // @ts-ignore
  <MatchVMProvider>
    <MatchImpl />
  </MatchVMProvider>
);

const MatchImpl: React.FC = observer(() => {
  const { isLoading, getLoadingText, controller } = useMatchVM();
  return isLoading ? (
    <Loader text={getLoadingText} />
  ) : (
    <div>
      <div
        className={cn(style.root, {
          [style.resultBlur]: false,
        })}
      >
        <Header />
        <RemainingCardsContainer />
        {!controller.showGameResult && <PlayGroundContainer />}
        <TreasureContainer />
      </div>
      {(controller.showGameResult || controller.showRoundResult) && (
        <GameResult
          round={controller.previousRoundNumber}
          isRoundResult={controller.showRoundResult}
          isGameResult={controller.showGameResult}
          points={
            controller.showRoundResult
              ? controller.roundResultPoints
              : controller.gameResultScore
          }
        />
      )}
      {controller.cardsModel.jokerRandomCards.length > 0 && (
        <JokerAbility cards={controller.cardsModel.jokerRandomCards} />
      )}
      {controller.debug.getDebuggerModalOpened && <DebugModal />}
      {controller.isLeavingGameModalOpened && <LeaveGameModal />}
      {controller.isRulesModalOpened && <RulesModal />}
    </div>
  );
});

export default Match;
