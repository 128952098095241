import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLSelectElement> {
  placeholder?: string;
  options: string[];
}

const Select: React.FC<IProps> = ({
  placeholder,
  options,
  value,
  onChange,
  ...rest
}) => {
  return (
    <select
      className={cn(style.root)}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {options.map((o) => (
        <option value={o}>{o}</option>
      ))}
    </select>
  );
};

export default Select;
