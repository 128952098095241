export type TRuleCardType = "joker" | "ace" | "king" | "queen" | "jack";
const fullNameValues = {
  jk: "joker",
  a: "ace",
  k: "king",
  q: "queen",
  j: "jack",
};
const rules = {
  joker: {
    title: "When you play a joker,",
    description:
      "discard the joker and draw 3 cards from the top of your deck. Then choose 1 of the 3 to either play or stash in your hand. ",
  },
  ace: {
    title: "If you play an ace",
    description: "and lose the trick, you lead the next trick.",
  },
  king: {
    title: "When you lead a king, ",
    description:
      "if your opponent has a card of this suit, they must play either the Ace of this suit or their highest ranked card of this suit.",
  },
  queen: {
    title: "When you play a queen, ",
    description:
      "you exchange one of your cards to a random card of your opponent",
  },
  jack: {
    title: "When you play a jack,",
    description: "you exchange one of your cards to a top card of the deck",
  },
};

export { rules, fullNameValues };
