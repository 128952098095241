import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import TreasureTitle from "./components/TreasureTitle";
import TreasureEmptyMessage from "./components/TreasureEmptyMessage";
import TreasureControls from "./components/TreasureControls";
import { observer } from "mobx-react";
import { useMatchVM } from "../../MatchVM";
import TreasureCardsContainer from "./components/TreasureCardsContainer";

const TreasureContainer: React.FC = observer(() => {
  const { controller } = useMatchVM();
  return (
    <div className={cn(style.root)}>
      <TreasureTitle />
      {controller.currentTieCards.length > 0 ? (
        <TreasureCardsContainer cards={controller.currentTieCards} />
      ) : (
        <TreasureEmptyMessage />
      )}
      <TreasureControls />
    </div>
  );
});

export default TreasureContainer;
