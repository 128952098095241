import RootStore from "./RootStore";
import { action, computed, makeObservable, observable } from "mobx";
import api from "../services/api";

export default class AuthStore {
  constructor(public rootStore: RootStore) {
    makeObservable(this);
  }

  @observable
  protected wallet: string | undefined;
  @action
  setWallet(wallet: string) {
    this.wallet = wallet;
  }
  @computed
  get getWallet() {
    return this.wallet;
  }

  @action
  signIn = async (wallet: string) => {
    if (this.rootStore.userStore.getUUID) return;
    this.rootStore.userStore.initialized = false;
    this.setWallet(wallet);
    const { data, error } = await api.auth.getProfile(wallet);
    if (error) {
      const createDTO = await api.auth.createProfile(wallet);
      if (createDTO.data) this.rootStore.userStore.setUser(createDTO.data);
      if (createDTO.error) throw new Error("Profile Creation failed");
    }
    if (data) this.rootStore.userStore.setUser(data);
    this.rootStore.userStore.initialized = true;
  };
  @action
  signOut = () => {
    this.rootStore.userStore.setUser(null);
    this.wallet = undefined;
  };
}
