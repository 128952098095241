import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { observer } from "mobx-react";
import { ICard } from "../../../../../../../../services/api/match";
import cardBack from "../../../../../../../../assets/svg/card-back.svg";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  card: ICard;
  back: string;
}

const OpponentCard: React.FC<IProps> = observer(({ card, back }) => {
  return (
    <div className={cn(style.root, style.opponent)}>
      <div className={cn("card-inner", style.inner)}>
        <div className={cn(style.front)}>
          <img
            src={back}
            alt={card.id}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = cardBack;
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default OpponentCard;
