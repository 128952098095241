import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { IPlayerDTO, TUUID } from "../../../services/api/auth";
import { ICard, IHistoryCard } from "../../../services/api/match";
import { RealtimeSubscription } from "@supabase/supabase-js";
import api from "../../../services/api";
import cardBack from "../../../assets/svg/card-back.svg";
import { etc } from "../../../utils/helpers";
import GameController from "./GameControllerVM";

export default class Player {
  constructor(uuid: TUUID, controller: GameController, deckURI?: string) {
    makeObservable(this);
    this.uuid = uuid;
    this.controller = controller;
    this.deckURI = deckURI;
  }

  protected readonly uuid: TUUID;
  @computed
  get getUUID(): TUUID {
    return this.uuid;
  }

  controller!: GameController;

  @observable
  protected readonly deckURI?: string;

  @observable
  protected initialized: boolean = false;
  @computed
  get isInitialized(): boolean {
    return this.initialized;
  }

  @observable
  protected deckCards: ICard[] = [];
  @computed
  get getDeckCards(): ICard[] {
    return this.deckCards;
  }

  @observable
  protected handCards: ICard[] = [];
  @computed
  get getMyUsedCards(): IHistoryCard[] {
    return this.controller.getHistory
      .flatMap((t) => t.c)
      .filter((c) => c.uuid === this.uuid);
  }
  @computed
  get getHandCards(): ICard[] {
    return [...this.handCards]
      .sort(etc.orderBy(["suit", "weight"], ["desc", "desc"]))
      .filter((c) => {
        if (!this.getMyUsedCards.find((hc) => hc.id === c.id)) return c;
      });
  }
  @action
  setHandCards(cards: ICard[]) {
    this.handCards = cards;
  }
  @observable
  protected cardsBack: string = cardBack;
  @computed
  get getCardsBack(): string {
    return this.cardsBack;
  }

  @action
  protected extractProfile = (profile: IPlayerDTO) => {
    runInAction(() => {
      if (profile.deck_cards) this.deckCards = profile.deck_cards;
      if (profile.hand_cards) this.handCards = profile.hand_cards;
      if (profile.cards_back) this.cardsBack = profile.cards_back;
    });
  };

  @observable
  protected profileWatcher?: RealtimeSubscription;

  @action
  dealNewHandCards = async () => {
    if (this.getHandCards.length > 0) return;
    const deck = [...this.getDeckCards];
    const hands = deck.slice(0, 13);
    deck.splice(0, 13);
    await api.match.users.updateCards(this.uuid, hands, deck);
    this.setHandCards(hands);
  };

  @action
  receiveCardToHands = async (cards: ICard[]) => {
    await api.match.users.updateHandCards(this.getUUID, [
      ...this.getHandCards,
      ...cards,
    ]);
  };
  @action
  _init = async () => {
    try {
      const profile = await api.match.users.getPlayerData(
        this.uuid,
        this.controller.getMatchId,
        this.deckURI
      );
      const profileWatcher =
        await api.match.users.createPlayerDataUpdateWatcher(
          this.uuid,
          this.extractProfile
        );

      runInAction(() => {
        this.extractProfile(profile);
        this.profileWatcher = profileWatcher;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.initialized = true;
    }
  };
}
