const cardWeightsByType = [
  {
    type: "ace",
    value: 1,
  },
  {
    type: "2",
    value: 2,
  },
  {
    type: "3",
    value: 3,
  },
  {
    type: "4",
    value: 4,
  },
  {
    type: "5",
    value: 5,
  },

  {
    type: "6",
    value: 6,
  },
  {
    type: "7",
    value: 7,
  },
  {
    type: "8",
    value: 8,
  },
  {
    type: "9",
    value: 9,
  },
  {
    type: "10",
    value: 10,
  },
  {
    type: "jack",
    value: 11,
  },
  {
    type: "queen",
    value: 12,
  },
  {
    type: "king",
    value: 13,
  },
  {
    type: "joker",
    value: 15,
  },
];

const getCardWeightsByType = (type: string) => {
  const extractedType = type.split("-")[1];
  if (!extractedType) return null;
  const t = cardWeightsByType.find((t) => t.type === extractedType);
  if (!t) return null;
  return t.value;
};

export default getCardWeightsByType;
