import React from "react";
import spade from "../../../../../../../../../../assets/svg/s.svg";
import diamond from "../../../../../../../../../../assets/svg/d.svg";
import heart from "../../../../../../../../../../assets/svg/h.svg";
import club from "../../../../../../../../../../assets/svg/c.svg";
import cn from "classnames";
import style from "./style.module.scss";
import { observer } from "mobx-react";
import { useMatchVM } from "../../../../../../../../MatchVM";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const Suits: React.FC<IProps> = observer(({ ...rest }) => {
  const { controller } = useMatchVM();
  return (
    <div className={cn(style.root, rest.className)} {...rest}>
      <img
        src={spade}
        alt="spade"
        className={cn({
          [style.active]: controller.cardsModel.getLeadingSuit === "spades",
        })}
      />
      <img
        src={heart}
        alt="heart"
        className={cn({
          [style.active]: controller.cardsModel.getLeadingSuit === "hearts",
        })}
      />
      <img
        src={diamond}
        alt="diamond"
        className={cn({
          [style.active]: controller.cardsModel.getLeadingSuit === "diamonds",
        })}
      />

      <img
        src={club}
        alt="club"
        className={cn({
          [style.active]: controller.cardsModel.getLeadingSuit === "clubs",
        })}
      />
    </div>
  );
});

export default Suits;
