import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import getCardWeightsByType from "./getCardWeight";
import { ICard } from "../services/api/match";
import { etc } from "./helpers";

export type TCardValue =
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "jack"
  | "queen"
  | "king"
  | "ace"
  | "joker";
export type TCardSuit = "spades" | "hearts" | "diamonds" | "clubs";
export type TCardType = `${TCardSuit}-${TCardValue}`;

export type IDeckDictionary = {
  [key in TCardType | "back"]: string;
};

const convertDeckDictionaryToArray = (dictionary: IDeckDictionary): ICard[] => {
  const temp = Object.assign({}, dictionary);
  const cards: ICard[] = [];
  Object.keys(temp).forEach(
    //@ts-ignore
    (type: `${TCardSuit}-${TCardValue}`) => {
      const item = temp[type];
      const typeFormatted = type.toLowerCase();
      const mark = typeFormatted.split("-")[1] as TCardValue;
      const suit = typeFormatted.split("-")[0] as TCardSuit;
      const weight = getCardWeightsByType(typeFormatted);
      if (weight) {
        const card: ICard = {
          id: uuid(),
          suit: suit,
          mark: mark,
          type: typeFormatted as TCardType,
          image: etc.replaceIPFSURI(item),
          weight: weight,
          rarityScore: 0,
        };
        cards.push(card);
      }
    },
    []
  );
  return cards;
};

export default convertDeckDictionaryToArray;
