import React, { useMemo } from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { useEthers } from "@usedapp/core";
import {
  useAccountTokenIDs,
  useAwakenedChampionTokenURIs,
} from "../../hooks/useDeck";
import { useCurrentNetworkId } from "../../hooks/useCurrentNetworkId";
import ConnectToNetworkButton from "../../components/ConnectToNetworkButton";
import ConnectWallet from "../../components/ConnectWallet";

const Decks: React.FC = () => {
  const { account, chainId } = useEthers();
  const expectedNetworkId = useCurrentNetworkId();
  const nftIds = useAccountTokenIDs();
  const hasBalance = nftIds.length > 0;
  const tokenURIs = useAwakenedChampionTokenURIs();

  const decksURIs = useMemo(() => tokenURIs.filter((t) => t.id), [tokenURIs]);

  if (!account) {
    return (
      <div className={cn(style.root)}>
        <ConnectWallet />
      </div>
    );
  }

  if (expectedNetworkId !== chainId) {
    return (
      <div className={cn(style.root)}>
        <ConnectToNetworkButton />
      </div>
    );
  }

  return (
    <div className={cn(style.root)}>
      <h1>Account: {account}</h1>

      {hasBalance ? (
        <ul className={cn(style.ul)}>
          {decksURIs.map(({ id, uri }) => (
            <li key={id}>
              <h5>ID: {id}</h5>
              <a href={uri} target="_blank" rel="noreferrer">
                {uri}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <p>You don't own any decks</p>
      )}
    </div>
  );
};

export default Decks;
