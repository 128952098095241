import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import LeadSuit from "./components/LeadSuit";
import RoundTitle from "./components/RoundTitle";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const UpperBlock: React.FC<IProps> = ({ ...rest }) => {
  return (
    <div className={cn(style.root, rest.className)}>
      <LeadSuit />
      <RoundTitle />
    </div>
  );
};

export default UpperBlock;
