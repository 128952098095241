import React from "react";
import ScoreTracker from "./components/ScoreTracker";
import UpperBlock from "./components/UpperBlock";
import cn from "classnames";
import style from "./style.module.scss";

const Header: React.FC = () => {
  return (
    <header className={cn(style.root)}>
      <UpperBlock />
      <ScoreTracker />
    </header>
  );
};

export default Header;
