import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import Button from "../../../../../../components/Button";
import { observer } from "mobx-react";
import { ISession } from "../../../../../../services/api/lobby";
import { useLobbiesVM } from "../../../../LobbiesVM";
import { etc } from "../../../../../../utils/helpers";

interface IProps {
  owner_uid: string;
  data: ISession;
}

const Session: React.FC<IProps> = observer(({ data, owner_uid }) => {
  const { selectSessionForJoin, uuid } = useLobbiesVM();
  return (
    <div className={cn(style.root)}>
      <div className={cn(style.players)}>
        <span className={cn("text regular")}>
          {etc.truncateAccount(data.owner_hash)}{" "}
          {uuid === data.owner_hash && "(you)"}
        </span>
        {data.opponent_hash && (
          <React.Fragment>
            <div className={cn(style.vs, "text title")}>vs</div>
            <span className={cn("text regular")}>
              {etc.truncateAccount(data.owner_hash)}
            </span>
          </React.Fragment>
        )}
      </div>
      {!data.opponent_hash &&
        (owner_uid === data.owner_hash ? (
          <span className={cn("text regular")}>Waiting opponent</span>
        ) : (
          <Button onClick={() => selectSessionForJoin(data.id)}>
            Join Battle
          </Button>
        ))}
    </div>
  );
});

export default Session;
