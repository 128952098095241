import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { observer } from "mobx-react";
import OpponentCard from "./components/OpponentCard";
import { useMatchVM } from "../../../../MatchVM";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const OpponentHandCards: React.FC<IProps> = observer(() => {
  const { controller } = useMatchVM();
  const { getOpponent } = controller;

  return (
    <TransitionGroup
      className={cn(style.root, {
        [style.dealt]:
          !controller.isDealing &&
          controller.getStatus !== "showing_round_result",
      })}
      id={"opponent-hand"}
    >
      {getOpponent.getHandCards.map((c, index) => (
        <CSSTransition
          key={c.id}
          timeout={700}
          classNames={cn("card-opponent")}
        >
          <OpponentCard card={c} key={index} back={getOpponent.getCardsBack} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
});

export default OpponentHandCards;
