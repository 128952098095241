import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { ICard } from "../../../../../../../../services/api/match";
import { observer } from "mobx-react";
import { useMatchVM } from "../../../../../../MatchVM";
import cardBack from "../../../../../../../../assets/svg/card-back.svg";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  cards: ICard[] | number;
  isOpponent?: boolean;
}

const CardsStack: React.FC<IProps> = observer(({ cards, isOpponent }) => {
  const { controller } = useMatchVM();
  const { getOwner, getOpponent } = controller;
  const displayCards = Array.isArray(cards) ? cards.slice(0, 5) : [...Array(5)];

  return (
    <div className={cn(style.root)}>
      {displayCards.map((c, index) => (
        <img
          src={isOpponent ? getOpponent.getCardsBack : getOwner.getCardsBack}
          alt={"Card"}
          key={index}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = cardBack;
          }}
        />
      ))}
    </div>
  );
});

export default CardsStack;
