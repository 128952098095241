import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { ILogMessage } from "../../../../../../models/LogsModel";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  message: ILogMessage;
}

const Message: React.FC<IProps> = ({ message, ...rest }) => {
  return (
    <div className={cn(style.root, rest.className)} {...rest}>
      {message.title && (
        <b className={cn("text message bold")}>{message.title}</b>
      )}
      {message.message && (
        <p className={cn("text message ")}>{message.message}</p>
      )}
    </div>
  );
};

export default Message;
