import React from "react";
import "./index.scss";
import App from "./App";
import { configure } from "mobx";
import { RootStore } from "./stores";
import { storesContext } from "./hooks/useStores";
import { DAppProvider } from "@usedapp/core";
import { getDAppConfig } from "./configs";
import { createRoot } from "react-dom/client";

configure({
  enforceActions: "never",
});

const rootStore = new RootStore();

const container = document.getElementById("root");

if (!container) throw new Error("Container not found");

const root = createRoot(container);
// @ts-ignore
const app = (
  <storesContext.Provider value={rootStore}>
    <DAppProvider config={getDAppConfig()}>
      <App />
    </DAppProvider>
  </storesContext.Provider>
);
root.render(app);

if (process.env.REACT_APP_STATE === "DEV") {
  console.log(`Build ID: ${process.env.REACT_APP_BUILD_COMMIT}`);
  console.log(`Build Time: ${process.env.REACT_APP_BUILD_TIME}`);
}
