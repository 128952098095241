import React from "react";
import style from "./style.module.scss";
import cn from "classnames";
import { NftInfo } from "../../../../../../hooks/useDeck";
import DeckItem from "../DeckItem";

interface IProps {
  decksURIs: NftInfo[];
  selectedDeckID: number | null;
}

const DecksList: React.FC<IProps> = ({ decksURIs, selectedDeckID }) => {
  return (
    <div className={cn(style.root)}>
      {decksURIs.length > 0 ? (
        decksURIs.map(({ id, uri }) => (
          <DeckItem
            key={id}
            deckID={id}
            selectedDeckID={selectedDeckID}
            deckURI={uri}
          ></DeckItem>
        ))
      ) : (
        <h1 className={cn(style.empty)}>You own no decks.</h1>
      )}
    </div>
  );
};

export default DecksList;
