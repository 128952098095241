import RootStore from "./RootStore";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { createUserUpdateWatcher, IUserDTO } from "../services/api/auth";
import { RealtimeSubscription } from "@supabase/supabase-js";

export default class UserStore {
  constructor(public rootStore: RootStore) {
    makeObservable(this);
  }

  @observable
  initialized: boolean = false;

  @observable
  protected inviteUUID: string | null = null;
  @computed
  get getInviteUUID(): string | null {
    return this.inviteUUID;
  }
  @action
  setInviteUUID = (uuid: string) => {
    this.inviteUUID = uuid;
  };

  @observable
  protected userChangeWatcher?: RealtimeSubscription;

  @observable
  protected user: IUserDTO | null = null;
  @computed
  get getPlayerProfile() {
    return this.user;
  }
  @computed
  get getUUID() {
    return this.getPlayerProfile?.account_hash;
  }
  @computed
  get getSelectedDeck() {
    return this.getPlayerProfile?.selected_deck ?? null;
  }
  @action
  setUser = async (userDTO: IUserDTO | null) => {
    if (!this.userChangeWatcher && userDTO)
      this.userChangeWatcher = await createUserUpdateWatcher(
        userDTO.account_hash,
        this.setUser
      );
    runInAction(() => {
      this.user = userDTO;
      this.initialized = true;
    });
  };
}
