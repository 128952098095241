import useStores from "../../hooks/useStores";
import useVM from "../../hooks/useVM";
import React, { useMemo } from "react";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { useParams } from "react-router-dom";
import UserStore from "../../stores/UserStore";
import api from "../../services/api";
import GameController from "./models/GameControllerVM";
import { useAwakenedChampionTokenURI } from "../../hooks/useDeck";

export default class MatchVM {
  constructor(matchId: number, userStore: UserStore, userDeckURI: string) {
    makeObservable(this);
    this.matchId = matchId;
    this.userStore = userStore;
    this.userDeckURI = userDeckURI;
    this._init().then();
  }

  protected readonly matchId: number;
  protected readonly userStore: UserStore;
  @observable
  protected readonly userDeckURI: string;

  public controller!: GameController;

  @computed
  get isControllerInitialized(): boolean {
    return this.controller && this.controller.isInitialized;
  }

  @observable
  protected loading: boolean = true;
  @computed
  get isLoading(): boolean {
    return this.loading || !this.controller || !this.controller.isInitialized;
  }
  @observable
  protected loadingText?: string;
  @computed
  get getLoadingText() {
    return this.loadingText;
  }

  @action
  setLoading = (text?: string) => {
    runInAction(() => {
      this.loading = true;
      this.loadingText = text;
    });
  };
  @action
  resetLoading = () => {
    runInAction(() => {
      this.loading = false;
      this.loadingText = undefined;
    });
  };

  protected _init = async () => {
    this.loading = true;
    if (!this.userStore.getUUID) return;
    try {
      const opponentUUID = await api.match.getOpponentPlayerUUID(
        this.userStore.getUUID,
        this.matchId
      );
      const controller = new GameController(
        this.matchId,
        opponentUUID,
        this.userStore.getUUID,
        this.userDeckURI
      );
      runInAction(() => {
        this.controller = controller;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.resetLoading();
    }
  };
}

const ctx = React.createContext<MatchVM | null>(null);

// @ts-ignore
export const MatchVMProvider: React.FC = ({ children }) => {
  const { userStore } = useStores();
  const { id } = useParams();
  if (!id) throw new Error("Match ID not provided");
  if (!userStore.getSelectedDeck) throw new Error("Deck not selected");
  const deckURI = useAwakenedChampionTokenURI(userStore.getSelectedDeck);
  const vm = useMemo(
    () => new MatchVM(+id, userStore, deckURI),
    [id, userStore, deckURI]
  );
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useMatchVM = () => useVM(ctx);
