import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  text?: string;
}

const Loader: React.FC<IProps> = ({ text = "Loading..." }) => {
  return <div className={cn(style.root, "text title")}>{text}</div>;
};

export default Loader;
