import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const PointsRow: React.FC<IProps> = ({ children, ...rest }) => {
  return (
    <tr className={cn(style.root)}>
      <td></td>
      <td></td>
      <td colSpan={4} className={"text small bold"}>
        50 points
      </td>
      <td colSpan={1} className={"text small bold"}>
        10 points
      </td>
      <td colSpan={1} className={"text small bold"}>
        20 points
      </td>
      <td colSpan={1} className={"text small bold"}>
        30 points
      </td>
      <td colSpan={3} className={"text small bold"}>
        50 points
      </td>
      <td colSpan={3} className={"text small bold"}>
        0 points
      </td>
      <td colSpan={1} className={"text small bold"}>
        75 points
      </td>
    </tr>
  );
};

export default PointsRow;
