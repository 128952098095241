import React, { useEffect } from "react";
import style from "./style.module.scss";
import cn from "classnames";
import { useLobbiesVM } from "../../../../LobbiesVM";
import { etc } from "../../../../../../utils/helpers";
import cardPlaceholder from "../../../../../../assets/svg/card-drop.svg";

interface IProps {
  deckID: number;
  selectedDeckID: number | null;
  deckURI: string;
}

const DeckItem: React.FC<IProps> = ({ deckID, selectedDeckID, deckURI }) => {
  const { setSelectedDeckID } = useLobbiesVM();

  const [cardBack, setCardBack] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    if (!deckURI.length || deckURI.length === 0) return;
    const fetchCardBack = async () => {
      const resp = await fetch(deckURI);
      const dto = await resp.json();
      const cardBack = etc.replaceIPFSURI(dto.ui.assets.deck?.back) ?? null;
      setCardBack(cardBack);
      setLoading(false);
    };

    fetchCardBack().catch(() => {
      setCardBack(cardPlaceholder);
      setLoading(false);
    });
  }, [deckURI]);

  return (
    <div
      key={deckID}
      className={cn(style.root, {
        [style.selected]: deckID === selectedDeckID,
      })}
      onClick={() => setSelectedDeckID(deckID)}
    >
      {loading && "Loading..."}
      {cardBack && (
        <img
          src={cardBack}
          alt={"Card Back"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = cardPlaceholder;
          }}
        />
      )}
    </div>
  );
};

export default DeckItem;
