import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import characterBlue from "../../assets/images/character_blue.png";
import characterOrange from "../../assets/images/character_orange.png";

import ConnectWallet from "../../components/ConnectWallet";
import Rule from "./components/Rule/Rule";
import { NavLink } from "react-router-dom";
import ConnectToNetworkButton from "../../components/ConnectToNetworkButton";
import Account from "../../components/Header/components/Account";
import { useEthers } from "@usedapp/core";
import { useCurrentNetworkId } from "../../hooks/useCurrentNetworkId";

const GuestBlock: React.FC = () => {
  const { account, chainId } = useEthers();
  const expectedNetworkId = useCurrentNetworkId();
  return (
    <div className={cn(style.root)}>
      <div className={cn(style.title)}>Welcome to Skirmish</div>
      <p className={cn(style.description)}>
        Skirmish is a trick-taking game (think spades) for two players, each
        player tries to win (or lose) tricks against the other over 3 rounds.
      </p>
      <div className={cn(style.actions)}>
        {expectedNetworkId !== chainId ? (
          <ConnectToNetworkButton />
        ) : (
          <React.Fragment>
            {account && <Account account={account} />}
            {!account && <ConnectWallet />}
          </React.Fragment>
        )}
      </div>
      <span className={cn(style.specialTitle)}>Special Card Abilities </span>
      <p className={cn(style.specialDesc)}>
        Non numbered cards (Ace, Jack, Queen, King, Joker) have special
        abilities, which activate when the card is played:
      </p>
      <div className={cn(style.slider)}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
        >
          <SwiperSlide>
            <Rule type={"joker"} />
          </SwiperSlide>
          <SwiperSlide>
            <Rule type={"ace"} />
          </SwiperSlide>
          <SwiperSlide>
            <Rule type={"king"} />
          </SwiperSlide>
          <SwiperSlide>
            <Rule type={"queen"} />
          </SwiperSlide>
          <SwiperSlide>
            <Rule type={"jack"} />
          </SwiperSlide>
        </Swiper>
      </div>
      <NavLink to={"/rules"} className={cn(style.rulesLink)}>
        Read the full rules
      </NavLink>
      <img src={characterBlue} alt="" width={301} />
      <img src={characterOrange} alt="" width={271} />
    </div>
  );
};

export default GuestBlock;
