import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { useEtherBalance, useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import useCurrency from "../../../../hooks/useCurrency";
import { etc } from "../../../../utils/helpers";
import useStores from "../../../../hooks/useStores";

interface IProps {
  account: string;
}
const Account: React.FC<IProps> = ({ account }) => {
  const { toFormattedEther, truncateAccount } = etc;
  const currency = useCurrency();
  const { deactivate } = useEthers();
  const etherBalance = useEtherBalance(account) || BigNumber.from(0);
  const handleSignOut = () => {
    deactivate();
  };
  return (
    <div className={cn(style.root)}>
      <div className={cn(style.currencyAmount)}>
        {toFormattedEther(etherBalance)}
      </div>
      <div className={cn(style.currencyName)}>{currency}</div>
      <div className={cn(style.walletAddress)}>{truncateAccount(account)}</div>
      <div className={cn(style.walletDisconnect)} onClick={handleSignOut}>
        Disconnect
      </div>
    </div>
  );
};

export default Account;
