import { BigNumber, constants } from 'ethers';

export const safelyUnwrapBN = (bn?: any): BigNumber => {
  return bn ? bn : BigNumber.from(0);
};

export const safelyUnwrapBNResponse = (bn?: any): BigNumber => {
  return safelyUnwrapBN(bn?.[0]);
};

export const hasError = (status: string): boolean => status === 'Exception' || status === 'Fail';
export const isPendingOrMining = (status: string): boolean => status === 'PendingSignature' || status === 'Mining';

export const toSafeAccount = (account: string | null | undefined): string => account || constants.AddressZero;
