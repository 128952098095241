import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import Rule from "../Guest/components/Rule";
import PageWithHeader from "../../layouts/PageWithHeader";

const RulesPage: React.FC = () => {
  return (
    <PageWithHeader>
      <div className={cn(style.root)}>
        <h1>Game Rules</h1>
        <h2>Overview</h2>
        <p>
          Pip is a trick-taking game for two players, each player cards to win
          tricks against the other over multiple rounds. Players score points
          during a round by winning tricks containing specific cards and at the
          end of a round based on the number of total tricks they won. The
          player with the most points at the end of the game is the winner.
        </p>
        <h3>Setup</h3>
        <p>
          Each player brings their own 52 card <span>DECK</span> to play. Each
          game consists of multiple <span>ROUNDS</span>. At the beginning of the
          first round, each player shuffles their deck and draws a HAND of 13
          cards. Subsequent rounds do not shuffle previously used cards back to
          the deck.
        </p>
        <h2>Gameplay</h2>
        <h3>Round overview</h3>
        <p>
          Each round consists of a series of 13 turns for each player, called
          <span> TRICKS</span>. Every trick, both players will play one card
          from their hand face up into the middle: one player will{" "}
          <span>LEAD</span> (that is, play the first card of the trick), and the
          other player will then FOLLOW (that is, play the second card of the
          trick). Based on the two cards that are played, one player will be
          said to <span>WIN THE TRICK</span>.
        </p>
        <h3>Trick Details</h3>
        <p>
          <span>LEADING</span>: The first player is determined by coin flip.
          After that (unless specified otherwise), the winner of one trick will
          lead the following trick. The leader can choose to play any card from
          their hand, without restriction. The suit of that card is known as the
          <span> LEAD SUIT</span> for that trick. The winner of a round leads
          the next round.
        </p>
        <p>
          <span>FOLLOWING</span>: After one player leads, the other player now
          must play a card that (if possible) matches the lead suit: that card
          can be (unless specified otherwise) of any rank in that suit. If the
          follower does not have a card in the lead suit, they may play any card
          without restriction.
        </p>
        <p>
          <span>DETERMINING THE TRICK WINNER</span>: After both cards have been
          played (and any abilities activated; see Abilities below), players
          determine the winner of the trick. Typically, the card with the
          highest rank in the lead suit wins the trick, with Aces being low.
          However, the <span>TRUMP SUIT</span> of spades is considered higher
          than the lead suit:
        </p>
        <ul>
          <li>
            {" "}
            If either card in the trick is the trump suit, the player who played
            the card in the trump suit with the highest rank wins the trick.
          </li>
          <li>
            If neither card in the trick is in the trump suit, the player who
            played the card in the lead suit with the highest rank wins the
            trick.
          </li>
          <li>
            If both cards are the same suit and rank, they are set aside as
            treasure for the winner of the next turn. The player who previously
            led the turn gets to lead again. In the case of another tie, this
            pattern repeats until one player wins the trick, at which point they
            acquire all the tricks set aside in the treasure chest.
          </li>
        </ul>
        <p>
          The winner of the trick takes the two cards and keeps them face down
          near their side of the table. The number of tricks won by each player
          is public information, visible to both players during the round.
          However, no player may look at the faces of cards in previously won
          tricks.
          <br />
          <br />
          The winner of a trick leads the next trick (unless otherwise
          specified). Continue to play tricks until all 13 tricks have been won.
        </p>
        <h3>Abilities</h3>
        <p>
          Non numbered cards (Ace, Jack, Queen, King, Joker) have special
          abilities, which activate when the card is played. They may change
          what your opponent can play, how the winner of the trick is
          determined, or who leads the next trick and they may let you take a
          special action when you play the card.
        </p>
        <p>
          {
            "All non numbered cards stay on the table for the trick and are counted based on their normal ranking (10 < J < Q < K). Draw / trade actions for the Queen and Jack occur separately and do not impact the outcome of the trick in which they were played. Only the Joker is temporarily placed on the table while the special action takes place and then is removed from play while a new card is selected to stand in for the trick."
          }
        </p>
        <div className={cn(style.abilities)}>
          <Rule type={"joker"} />
          <Rule type={"ace"} />
          <Rule type={"king"} />
          <Rule type={"queen"} />
          <Rule type={"jack"} />
        </div>
        <h3>End of round scoring</h3>
        <p>
          After all 13 tricks have been played, count how many tricks each
          player has won. Players may win points at the end of the round, based
          on the number of tricks they won. (See the chart below.) You want to
          win more tricks than your opponent, but not too many.
        </p>
        <table>
          <thead>
            <tr>
              <th>Tricks</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0-3</td>
              <td>50</td>
            </tr>
            <tr>
              <td>4</td>
              <td>10</td>
            </tr>
            <tr>
              <td>5</td>
              <td>20</td>
            </tr>
            <tr>
              <td>6</td>
              <td>30</td>
            </tr>
            <tr>
              <td>7-9</td>
              <td>50</td>
            </tr>
            <tr>
              <td>10-12</td>
              <td>0</td>
            </tr>
            <tr>
              <td>13</td>
              <td>75</td>
            </tr>
          </tbody>
          <p>
            After the end-of-round scoring, set aside all cards taken during
            tricks and repeat setup with remaining cards left in each player's
            deck.
          </p>
          <h3>End of Game</h3>
          <p>
            Play 3 complete rounds. The player with the most points wins! If
            there is a tie, the player who won the most points during the last
            round wins.
          </p>
        </table>
      </div>
    </PageWithHeader>
  );
};

export default RulesPage;
