import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps {
  label?: string | React.ReactElement;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<IProps> = ({
  label,
  value,
  checked,
  onChange,
  ...rest
}) => {
  return (
    <label
      {...rest}
      className={cn(style.root, "text regular", { [style.checked]: checked })}
    >
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      {label}
    </label>
  );
};

export default RadioButton;
