import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import RoundMarker from "../RoundMarker";
import PlayerMark from "../../PlayerMark";
import { useMatchVM } from "../../../../../../MatchVM";
import { observer } from "mobx-react";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const RoundsRow: React.FC<IProps> = observer(({ children, ...rest }) => {
  const { controller } = useMatchVM();
  const { opponentTricksScore, ownerTricksScore } = controller;
  const rounds: JSX.Element[] = [];
  for (let i = 0; i <= 13; i++) rounds.push(<RoundMarker number={i} />);
  return (
    <tr className={cn(style.root)}>
      <td className={cn("text default")}>Tricks</td>
      <td className={"text default"} style={{ padding: 0 }}>
        <div className={cn(style.players)}>
          <span>Opponent</span>
          <span>You</span>
        </div>
      </td>
      {rounds.map((r, i) => (
        <td key={i}>{r}</td>
      ))}
      <PlayerMark score={opponentTricksScore} isOpponent={true} />
      <PlayerMark score={ownerTricksScore} />
    </tr>
  );
});

export default RoundsRow;
