import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import style from "./style.module.scss";
import { LobbiesVMProvider, useLobbiesVM } from "./LobbiesVM";
import Sessions from "./components/Sessions";
import SelectDeckModal from "./components/SelectDeckModal";

const LobbiesList: React.FC = () => (
  // @ts-ignore
  <LobbiesVMProvider>
    <LobbiesImpl />
  </LobbiesVMProvider>
);

const LobbiesImpl: React.FC = observer(() => {
  const { isDeckSelectionModelOpened, getSelectedDeck, getSessionToJoinID } =
    useLobbiesVM();
  return (
    <div className={cn(style.root)}>
      <Sessions />
      {isDeckSelectionModelOpened && (
        <SelectDeckModal
          selectedDeck={getSelectedDeck}
          sessionToJoinID={getSessionToJoinID}
        />
      )}
    </div>
  );
});

const Lobbies: React.FC = () => {
  return <LobbiesList />;
};

export default Lobbies;
