import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { rules, TRuleCardType } from "../../../../misc/reusableTexts";
import joker from "../../../../assets/svg/joker-picto.svg";
import ace from "../../../../assets/svg/ace-picto.svg";
import king from "../../../../assets/svg/king-picto.svg";
import queen from "../../../../assets/svg/queen-picto.svg";
import jack from "../../../../assets/svg/jack-picto.svg";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  type: TRuleCardType;
}

const images = {
  joker: joker,
  ace: ace,
  king: king,
  queen: queen,
  jack: jack,
};

const AbilityTooltip: React.FC<IProps> = ({ type, children, ...rest }) => {
  return (
    <div {...rest} className={cn("ability-tooltip", style.root)}>
      <div className={cn(style.heading)}>
        <img src={images[type]} alt={type} />
        <span className={cn("text")}>{type}</span>
      </div>
      <div className={style.description}>
        {rules[type].title} {rules[type].description}
      </div>
    </div>
  );
};

export default AbilityTooltip;
