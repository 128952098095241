import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import RadioButton from "../../../RadioButton";
import { observer } from "mobx-react";
import { useMatchVM } from "../../../../pages/Match/MatchVM";
import { TCardSuit } from "../../../../utils/convertDeckDictionaryToArray";

interface IProps {}

const CardsBySuit: React.FC<IProps> = observer(({ ...rest }) => {
  const { controller } = useMatchVM();
  const [suit, setSuit] = React.useState<TCardSuit>(
    controller.debug.getSelectedSuit
  );

  const handleChange = (v: TCardSuit) => {
    setSuit(v);
    controller.debug.setSelectedSuit(v);
  };
  return (
    <div>
      <div className={cn("text default", style.title)}>Select Suit</div>
      <div className={cn(style.suits)}>
        <RadioButton
          label={"Club"}
          value={suit}
          checked={suit === "clubs"}
          onChange={(e) => handleChange("clubs")}
        />
        <RadioButton
          label={"Heart"}
          value={suit}
          checked={suit === "hearts"}
          onChange={(e) => handleChange("hearts")}
        />
        <RadioButton
          label={"Spades"}
          value={suit}
          checked={suit === "spades"}
          onChange={(e) => handleChange("spades")}
        />
        <RadioButton
          label={"Diamond"}
          value={suit}
          checked={suit === "diamonds"}
          onChange={(e) => handleChange("diamonds")}
        />
      </div>
    </div>
  );
});

export default CardsBySuit;
