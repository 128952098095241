import { Chain, ChainId, Localhost, MoonbaseAlpha, Moonriver } from '@usedapp/core';

export const toChain = (id?: ChainId): Chain => {
  if (ChainId.Localhost === id) return Localhost;
  if (ChainId.Moonriver === id) return Moonriver;
  if (ChainId.MoonbaseAlpha === id) return MoonbaseAlpha;
  return MoonbaseAlpha;
};

const toChainCurrency = (id: number): string => {
  if (ChainId.Localhost === id) return 'ETH';
  if (ChainId.MoonbaseAlpha === id) return 'DEV';
  return 'MOVR';
};

const toRPCUrls = (id: number): string[] => {
  if (ChainId.Localhost === id) return [];
  if (ChainId.MoonbaseAlpha === id) return ['https://rpc.testnet.moonbeam.network'];
  return ['https://rpc.moonriver.moonbeam.network'];
};

const toRPCBlockExplorerURLs = (id: number): string[] => {
  if (ChainId.Localhost === id) return [];
  if (ChainId.MoonbaseAlpha === id) return ['https://moonbase.moonscan.io'];
  return ['https://moonriver.moonscan.io'];
};

export const toChainParams = (chainId: number) => {
  const chain = toChain(chainId);

  return [
    {
      chainId: `0x${chainId.toString(16)}`,
      chainName: chain.chainName,
      nativeCurrency: {
        name: chain.chainName,
        symbol: toChainCurrency(chainId),
        decimals: 18,
      },
      rpcUrls: toRPCUrls(chainId),
      blockExplorerUrls: toRPCBlockExplorerURLs(chainId),
    },
  ];
};
