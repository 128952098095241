import React from "react";
import style from "./style.module.scss";
import cn from "classnames";
import CardRulePictogram from "../CardRulePictogram";
import { rules, TRuleCardType } from "../../../../misc/reusableTexts";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  type: TRuleCardType;
}

const Rule: React.FC<IProps> = ({ type }) => {
  return (
    <div className={cn("ability-rule", style.root)}>
      <CardRulePictogram type={type} />
      <div className={cn(style.description)}>
        <span>{rules[type].title}</span>
        <p>{rules[type].description}</p>
      </div>
    </div>
  );
};

export default Rule;
