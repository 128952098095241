import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import joker from "../../../../assets/svg/joker-picto.svg";
import ace from "../../../../assets/svg/ace-picto.svg";
import king from "../../../../assets/svg/king-picto.svg";
import queen from "../../../../assets/svg/queen-picto.svg";
import jack from "../../../../assets/svg/jack-picto.svg";

const images = {
  joker: joker,
  ace: ace,
  king: king,
  queen: queen,
  jack: jack,
};

type TPictogramType = "ace" | "king" | "queen" | "jack" | "joker";

interface IProps {
  type: TPictogramType;
}

const CardRulePictogram: React.FC<IProps> = ({ type }) => {
  return (
    <div className={cn("ability-rule-picture", style.root)}>
      <img className={cn(style.picture)} src={getPicture(type)} alt="" />
      <span className={cn("text")}>{type}</span>
    </div>
  );
};

const getPicture = (type: TPictogramType) => {
  return images[type];
};

export default CardRulePictogram;
