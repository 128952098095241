import React, { ChangeEvent } from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps {
  label?: string;
  value: string;
  checked: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox: React.FC<IProps> = ({
  label,
  value,
  checked,
  onChange,
  ...rest
}) => {
  return (
    <label {...rest} className={cn(style.root, "text regular")}>
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={(e) => onChange(e)}
      />
      {label}
    </label>
  );
};

export default CheckBox;
