import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { IHistoryCard } from "../../../../../../services/api/match";
import CardImage from "../../../PlayGroundContainer/components/CardImage";

interface IProps {
  cards: IHistoryCard[];
}

const TreasureCardsContainer: React.FC<IProps> = ({ cards }) => {
  return (
    <div className={cn(style.root)}>
      {cards.map((card, index) => (
        <CardImage card={card} key={index} />
      ))}
    </div>
  );
};

export default TreasureCardsContainer;
