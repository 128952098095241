import { ChainId, Config, Moonriver} from "@usedapp/core";

export const getDAppConfig = (): Config => ({
  readOnlyChainId: Moonriver.chainId,
  readOnlyUrls: {
    [Moonriver.chainId]: 'https://moonriver.api.onfinality.io/public',
  },
});

export type ContractAddresses = {
  multicall: string;
  awakenedChampion?: string;
};

const contractConfig: Record<number, ContractAddresses> = {
  [ChainId.Moonriver]: {
    awakenedChampion: '0x8a0594343fea49bDC295D2dE55EA1A60e261ab16',
    multicall: Moonriver.multicallAddress,
  },
};

export default contractConfig;
