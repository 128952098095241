import React, { useMemo } from 'react';
import Button from "../Button";
import cn from "classnames";
import style from "./style.module.scss";
import { useEthers } from '@usedapp/core';
import { useCurrentNetworkId } from "../../hooks/useCurrentNetworkId";
import { toChain, toChainParams } from "../../configs/toChainParams";

type Props = {
  size?: 'large' | 'medium';
};

const ConnectToNetworkButton: React.FC<Props> = ({ size }) => {
  const { library } = useEthers();
  const currentNetworkId = useCurrentNetworkId();
  const params = useMemo(() => toChainParams(currentNetworkId), [currentNetworkId]);
  const { chainName } = toChain(currentNetworkId);

  const handleClick = () => {
    library?.send('wallet_addEthereumChain', params);
  };

  return (
    <Button className={cn(style.root)} color="primary" onClick={handleClick}>
      Connect to {chainName}
    </Button>
  );
};

export default ConnectToNetworkButton;
