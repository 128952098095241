import React from "react";
import cn from "classnames";
import style from "./style.module.scss";

interface IProps extends React.HTMLProps<HTMLInputElement> {
  placeholder?: string;
}

const Input: React.FC<IProps> = ({ placeholder, value, onChange, ...rest }) => {
  return (
    <input
      className={cn(style.root)}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Input;
