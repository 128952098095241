import {
  ICard,
  IHistoryCard,
  IHistoryTrick,
  IMatchDTO,
  IUpdateMatchDTO,
  TMatchStatus,
} from "../../../services/api/match";
import { TUUID } from "../../../services/api/auth";
import Player from "./PlayerVM";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import api from "../../../services/api";
import supabase from "../../../services/api/supabase";
import DebugVM from "./DebugVM";
import { IPlayedCard } from "./CardsModel";
import CardsModel from "./CardsModel";
import LogsModel from "./LogsModel";
import { cardHelpers } from "../../../utils/helpers";
import { TCardSuit } from "../../../utils/convertDeckDictionaryToArray";

export type TLeadingSuit = TCardSuit | undefined;
export const TRUMP_SUIT_WEIGHT = 100;
export const TRICKS_PER_ROUND = 13;

export default class GameController {
  constructor(
    matchId: number,
    opponentUUID: string,
    ownerUUID: string,
    ownerURI: string
  ) {
    makeObservable(this);
    this.matchId = matchId;
    this.debug = new DebugVM(this);
    this.cardsModel = new CardsModel(this);
    this.logs = new LogsModel(this);
    this._init(opponentUUID, ownerUUID, ownerURI).then();
  }

  @observable
  protected initialized: boolean = false;

  @observable
  protected maxTricksInRound: number = TRICKS_PER_ROUND;
  @action
  setMaxTricksInRound(amount: number) {
    this.maxTricksInRound = amount;
  }
  @computed
  get getMaxTricksInRound(): number {
    return this.maxTricksInRound;
  }

  @computed
  get isInitialized(): boolean {
    return this.initialized;
  }

  @observable
  protected loading: boolean = true;

  @observable
  protected handlingRequest: boolean = false;

  protected readonly matchId: number;
  @computed
  get getMatchId(): number {
    return this.matchId;
  }

  protected owner!: Player;
  @computed
  get getOwner(): Player {
    return this.owner;
  }
  protected opponent!: Player;
  @computed
  get getOpponent(): Player {
    return this.opponent;
  }
  readonly debug: DebugVM;
  readonly cardsModel: CardsModel;
  readonly logs: LogsModel;

  @observable
  history: IHistoryTrick[] = [];
  @computed
  get getHistory(): IHistoryTrick[] {
    return [...this.history].sort((a, b) =>
      // sort by round and trick in asc
      a.r === b.r ? (a.t > b.t ? 1 : -1) : a.r > b.r ? 1 : -1
    );
  }

  @observable
  protected playerTurnUUID!: TUUID;

  @computed
  get getPlayerTurnUUID(): TUUID {
    return this.playerTurnUUID;
  }
  @computed
  get isOwnerTurn(): boolean {
    return this.playerTurnUUID === this.owner?.getUUID;
  }
  @computed
  get isCardCanBePlayed(): boolean {
    return (
      this.isOwnerTurn &&
      this.getStatus === "in_progress" &&
      !this.cardsModel.isHandlingAbilityRequest
    );
  }

  @observable
  protected status: TMatchStatus = "dealing";
  @computed
  get getStatus(): TMatchStatus {
    return this.status;
  }

  @observable
  protected showRibbon: boolean = false;
  @computed
  get isShowingRibbon(): boolean {
    return this.showRibbon;
  }
  @action
  setRoundRibbonState = (state: boolean) => {
    this.showRibbon = state;
  };

  @action
  protected setMatchData = (data: IMatchDTO) => {
    runInAction(() => {
      console.log("setMatchData");
      this.playerTurnUUID = data.player_turn_uuid;
      if (this.isOwnerTurn && data.status === "in_progress")
        this.setRoundRibbonState(true);
      this.status = data.status;
    });
  };

  @observable
  protected leaveGameModalOpened: boolean = false;
  @computed
  get isLeavingGameModalOpened(): boolean {
    return this.leaveGameModalOpened;
  }
  @action
  public setLeaveModalState = (state: boolean) => {
    this.leaveGameModalOpened = state;
  };

  @observable
  protected rulesModalOpened: boolean = false;
  @computed
  get isRulesModalOpened(): boolean {
    return this.rulesModalOpened;
  }
  @action
  public setRulesModalState = (state: boolean) => {
    this.rulesModalOpened = state;
  };

  @computed
  get isDealing(): boolean {
    return this.status === "dealing";
  }
  @computed
  protected get currentTrick(): IHistoryTrick | undefined {
    return this.getHistory[this.getHistory.length - 1];
  }
  @computed
  get currentTrickCards(): IHistoryCard[] {
    return this.currentTrick?.c ?? [];
  }
  @computed
  get currentTrickCardsWithOutJoker(): IHistoryCard[] {
    return [...this.currentTrickCards].filter((c) => c.v !== "joker");
  }
  @computed
  protected get currentTrickWinner(): TUUID | undefined {
    return this.currentTrick
      ? cardHelpers.definePairWinner(this.currentTrick?.c, this.owner.getUUID)
      : undefined;
  }
  @computed
  get currentTrickWinnerLabel(): string {
    if (!this.currentTrickWinner) return "tie";
    return this.currentTrickWinner === this.owner.getUUID ? "win" : "loss";
  }
  @computed
  get currentRoundWinnerLabel(): string {
    if (!this.currentTrickWinner) return "tie";
    return this.currentTrickWinner === this.owner.getUUID ? "win" : "loss";
  }
  @computed
  get currentRoundNumber(): number {
    return this.currentTrick?.r ?? 1;
  }
  @computed
  get previousRoundNumber(): number {
    return this.currentRoundNumber > 1
      ? this.currentRoundNumber - 1
      : this.currentRoundNumber;
  }

  @computed
  get currentTieCards(): IHistoryCard[] {
    const currentRoundTricks = [...this.getHistory]
      .filter((t) => t.r === this.currentRoundNumber)
      .map((t) => ({
        ...t,
        c: t.c.filter((c) => c.v !== "joker"),
      }));

    return currentRoundTricks.reduce((acc: IHistoryCard[], t) => {
      const pairWinner = cardHelpers.definePairWinner(t.c, this.owner.getUUID);
      if (pairWinner) return [];
      if (t.c.length >= 2) acc.push(...t.c);
      return acc;
    }, []);
  }

  @computed
  get currentTrickNumber(): number {
    return this.currentTrick?.t ?? 1;
  }
  @computed
  get currentLeadingSuit(): TLeadingSuit {
    const trickCardsWithoutNonSuit = this.currentTrick?.c.filter(
      (c) => c.v !== "joker"
    );
    const firstPlacedCard =
      trickCardsWithoutNonSuit && trickCardsWithoutNonSuit[0];
    if (trickCardsWithoutNonSuit && trickCardsWithoutNonSuit.length >= 2)
      return undefined;
    return firstPlacedCard?.s ?? undefined;
  }
  @computed
  get ownerTricksScore(): number {
    return cardHelpers.getPlayerTrickScoreByID(
      this.getHistory,
      this.owner.getUUID,
      this.currentRoundNumber
    );
  }
  @computed
  get opponentTricksScore(): number {
    return cardHelpers.getPlayerTrickScoreByID(
      this.getHistory,
      this.opponent.getUUID,
      this.currentRoundNumber
    );
  }
  @computed
  protected get ownerRoundScore(): number {
    const winedTricks = cardHelpers.getPlayerTrickScoreByID(
      this.getHistory,
      this.owner.getUUID,
      this.previousRoundNumber
    );
    return cardHelpers.calculatePointsByTricks(winedTricks);
  }
  @computed
  protected get opponentRoundScore(): number {
    const winedTricks = cardHelpers.getPlayerTrickScoreByID(
      this.getHistory,
      this.opponent.getUUID,
      this.previousRoundNumber
    );
    return cardHelpers.calculatePointsByTricks(winedTricks);
  }

  @computed
  get roundResultPoints(): number[] {
    return [this.ownerRoundScore, this.opponentRoundScore];
  }

  @computed
  get ownerPointsScore(): number[] {
    return this.calculatePointsScoreByUIID(this.owner.getUUID);
  }
  @computed
  get opponentPointsScore(): number[] {
    return this.calculatePointsScoreByUIID(this.opponent.getUUID);
  }
  @computed
  get gameResultScore(): number[] {
    const ownerPoints = this.ownerPointsScore.reduce(
      (acc: number, value: number) => acc + value,
      0
    );
    const opponentPoints = this.opponentPointsScore.reduce(
      (acc: number, value: number) => acc + value,
      0
    );
    return [ownerPoints, opponentPoints];
  }

  @computed
  get gameWinnerUUID(): TUUID | null {
    return this.gameResultScore[0] > this.gameResultScore[1]
      ? this.owner.getUUID
      : this.gameResultScore[0] === this.gameResultScore[1]
      ? null
      : this.opponent.getUUID;
  }

  @computed
  get ownerPlayedCard(): IHistoryCard | undefined {
    const userCards = this.currentTrickCards.filter(
      (c) => c.uuid === this.owner.getUUID
    );
    const card =
      userCards.length > 1
        ? userCards.find((c) => c.v !== "joker")
        : userCards[0];
    return card;
  }
  @computed
  get opponentPlayedCard(): IHistoryCard | undefined {
    const userCards = this.currentTrickCards.filter(
      (c) => c.uuid === this.opponent.getUUID
    );
    const card =
      userCards.length > 1
        ? userCards.find((c) => c.v !== "joker")
        : userCards[0];
    return card;
  }

  protected getNextTurnUserUUID(
    playedCards: IHistoryCard[]
  ): TUUID | undefined {
    const ownerCard = playedCards.find((c) => c.uuid === this.owner.getUUID);
    const opponentCard = playedCards.find(
      (c) => c.uuid === this.opponent.getUUID
    );
    const trickWinner = cardHelpers.definePairWinner(
      playedCards,
      this.owner.getUUID
    );

    if (ownerCard?.v === "ace" && trickWinner === this.opponent.getUUID)
      return this.owner.getUUID;
    if (opponentCard?.v === "ace" && trickWinner === this.owner.getUUID)
      return this.opponent.getUUID;

    return !trickWinner
      ? this.isOwnerTurn
        ? this.opponent.getUUID
        : this.owner.getUUID
      : trickWinner;
  }

  @computed
  get showTrickResult(): boolean {
    return (
      this.getStatus === "showing_trick_result" &&
      this.currentTrickCards.length >= 2
    );
  }
  @computed
  get showRoundResult(): boolean {
    return this.getStatus === "showing_round_result";
  }
  @computed
  get showGameResult(): boolean {
    return this.getStatus === "showing_game_result";
  }

  @action
  handleTurnChange = async (currentCards: IHistoryCard[], card?: ICard) => {
    let matchUpdateDTO: IUpdateMatchDTO = {};
    if (!card) {
      if (currentCards.length >= 2) {
        matchUpdateDTO = this.createMatchUpdateDTO(currentCards);
        await this.defineTrickWinner(this.currentTrickNumber);
      } else {
        matchUpdateDTO["player_turn_uuid"] = this.opponent.getUUID;
      }
    } else {
      const builtCard = this.convertCardToHistoryCard(card);
      const isExistAfterUpdate = [...currentCards].find(
        (c) => c.id === builtCard.id
      );
      const playedCards = [...currentCards];
      if (!isExistAfterUpdate) playedCards.push(builtCard);
      if (playedCards.length >= 2) {
        matchUpdateDTO = this.createMatchUpdateDTO(playedCards);
        await this.defineTrickWinner(this.currentTrickNumber);
      } else {
        matchUpdateDTO["player_turn_uuid"] = this.opponent.getUUID;
      }
    }

    await api.match.updateMatch(this.getMatchId, matchUpdateDTO);
  };

  @action
  storeHistoryMove = async (card: IPlayedCard) => {
    const currentMoves = [...this.currentTrickCards];
    if (currentMoves.length < 4)
      await this.appendHistoryTrick(currentMoves, card);
    if (!this.currentTrick) await this.initHistoryTrick();
  };

  appendHistoryTrick = async (
    currentMoves: IHistoryCard[],
    card: IPlayedCard
  ) => {
    await api.match.history.appendCardToHistoryTricks(this.getMatchId, {
      r: this.currentRoundNumber,
      t: this.currentTrickNumber,
      c: [
        ...currentMoves,
        {
          id: card.id,
          o: currentMoves.length + 1,
          v: card.mark,
          s: card.suit,
          image: card.image,
          uuid: card.user_uuid,
        },
      ],
    });
  };
  initHistoryTrick = async () => {
    const nextTrickNumber = this.currentTrickNumber + 1;
    await api.match.history.addHistoryTrick(this.getMatchId, {
      r: this.currentRoundNumber,
      t: nextTrickNumber,
      c: [],
    });
  };
  initHistoryRound = async () => {
    const nextRoundNumber = this.currentRoundNumber + 1;
    await api.match.history.addHistoryTrick(this.getMatchId, {
      r: nextRoundNumber,
      t: 1,
      c: [],
    });
  };
  removeHistoryCard = async (card: IHistoryCard) => {
    await api.match.history.appendCardToHistoryTricks(this.getMatchId, {
      r: this.currentRoundNumber,
      t: this.currentTrickNumber,
      c: this.currentTrickCards.filter((c) => c.id !== card.id),
    });
  };

  @action
  defineTrickWinner = async (trickNumber: number) => {
    const callbackByTrick =
      trickNumber === this.getMaxTricksInRound
        ? this.initHistoryRound
        : this.initHistoryTrick;
    const statusByTrick =
      trickNumber === this.getMaxTricksInRound
        ? "showing_round_result"
        : "in_progress";
    await this.applyMatchStatusChange(
      "showing_trick_result",
      1000,
      statusByTrick,
      callbackByTrick
    );
  };
  @action
  defineRoundWinner = async () => {
    if (!this.isOwnerTurn) return;
    setTimeout(async () => {
      if (this.currentRoundNumber < 4)
        await this.applyMatchStatusChange("dealing", 2000, "in_progress");
      else await this.applyMatchStatusChange("showing_game_result", 2000);
      await this.owner.dealNewHandCards();
      await this.opponent.dealNewHandCards();
      await this.defineWhoLeadAfterRoundResult();
    }, 4000);
  };
  @action
  defineGameWinner = async () => {
    await supabase.removeAllSubscriptions();
    if (!this.isOwnerTurn) return;
  };

  @action
  protected handleHistoryUpdate = async (trick: IHistoryTrick) => {
    this.history = this.history.map((t) => (t.id === trick.id ? trick : t));
  };
  @action
  protected handleHistoryInsert = async (trick: IHistoryTrick) => {
    this.history = this.history.concat(trick);
  };

  @action
  matchUpdateHandler = async (match: IMatchDTO) => {
    this.setMatchData(match);
    if (match.status === "showing_round_result") await this.defineRoundWinner();
    if (match.status === "showing_game_result") await this.defineGameWinner();
  };

  protected _init = async (
    opponentUUID: string,
    ownerUUID: string,
    ownerURI: string
  ) => {
    await supabase.removeAllSubscriptions();

    const matchData = await api.match.getMatchById(this.matchId);
    await api.match.createMatchUpdateWatcher(
      this.matchId,
      this.matchUpdateHandler
    );
    const history = await api.match.history.getHistoryTricks(this.getMatchId);
    await api.match.history.createHistoryWatcher(
      this.matchId,
      this.handleHistoryInsert,
      this.handleHistoryUpdate
    );

    runInAction(() => {
      this.setMatchData(matchData);
      this.history = history;
      this.initialized = true;
    });

    this.owner = new Player(ownerUUID, this, ownerURI);
    this.opponent = new Player(opponentUUID, this);

    await this.owner._init();
    await this.opponent._init();

    if (this.getStatus === "dealing")
      setTimeout(() => {
        this.applyMatchStatusChange("in_progress");
      }, 2000);
  };

  @action
  public leaveGame = async () => {
    await api.match
      .updateMatch(this.matchId, {
        winner_uuid: this.gameWinnerUUID,
        status: "finished",
      })
      .then(() => {
        window.location.href = "/";
      });
    this.leaveGameModalOpened = false;
  };

  protected createMatchUpdateDTO = (playedCards: IHistoryCard[]) => {
    const matchUpdateDTO: IUpdateMatchDTO = {};
    const filteredCards = playedCards.filter((c) => c.v !== "joker");
    const ownerCard = filteredCards.find((c) => c.uuid === this.owner.getUUID);
    const opponentCard = filteredCards.find(
      (c) => c.uuid === this.opponent.getUUID
    );
    if (ownerCard && opponentCard) {
      const winnerUUID = this.getNextTurnUserUUID(playedCards)
        ? this.getNextTurnUserUUID(playedCards)
        : this.owner.getUUID;

      matchUpdateDTO["player_turn_uuid"] = winnerUUID;
    }
    return matchUpdateDTO;
  };
  protected defineWhoLeadAfterRoundResult = async () => {
    const matchUpdateDTO: IUpdateMatchDTO = {};
    matchUpdateDTO["player_turn_uuid"] =
      this.ownerRoundScore > this.opponentRoundScore
        ? this.owner.getUUID
        : this.opponent.getUUID;
    await api.match.updateMatch(this.getMatchId, matchUpdateDTO);
  };

  protected calculatePointsScoreByUIID = (uuid: TUUID) => {
    //@ts-ignore
    // Type 'Set ' can only be iterated through when using the '--downlevelIteration' flag or with a '--target' of 'es2015' or higher.
    // not necessary
    const roundsIndexes = [...new Set(this.getHistory.map((h) => h.r))];
    return roundsIndexes.map((r) => {
      const tricksFromHistory = this.getHistory.filter((t) => t.r === r);
      const winedTricks = cardHelpers.getPlayerTrickScoreByID(
        this.getHistory,
        uuid,
        r
      );
      const points = cardHelpers.calculatePointsByTricks(winedTricks);

      return tricksFromHistory.length === this.getMaxTricksInRound &&
        tricksFromHistory[tricksFromHistory.length - 1]?.c.length >= 2
        ? points
        : 0;
    });
  };

  protected applyMatchStatusChange = async (
    to: TMatchStatus,
    delay: number = 1000,
    resetValue?: TMatchStatus,
    callback?: () => void
  ) => {
    await api.match.updateMatch(this.getMatchId, { status: to });
    if (resetValue || callback)
      setTimeout(async () => {
        if (callback) await callback();
        if (resetValue) {
          await api.match.updateMatch(this.matchId, { status: resetValue });
        } else
          await api.match.updateMatch(this.matchId, { status: "in_progress" });
      }, delay);
  };
  protected convertCardToHistoryCard = (card: ICard): IHistoryCard => {
    return {
      id: card.id,
      o: 1,
      v: card.mark,
      s: card.suit,
      image: card.image,
      uuid: this.owner.getUUID,
    };
  };
}
