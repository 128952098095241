import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { ICard, IHistoryCard } from "../../../services/api/match";
import api from "../../../services/api";
import { RealtimeSubscription } from "@supabase/supabase-js";
import { TUUID } from "../../../services/api/auth";
import GameController, { TLeadingSuit } from "./GameControllerVM";
import { TCardValue } from "../../../utils/convertDeckDictionaryToArray";

export interface IPlayedCard extends ICard {
  user_uuid: TUUID;
}

export default class CardsModel {
  protected controller: GameController;

  constructor(controller: GameController) {
    makeObservable(this);
    this.controller = controller;
    this._sync();
  }

  @observable
  protected usedCard?: ICard;
  @computed
  get getUsedCard(): ICard | undefined {
    return this.usedCard;
  }

  @observable
  protected handlingAbilityRequest: boolean = false;
  @computed
  get isHandlingAbilityRequest(): boolean {
    return this.handlingAbilityRequest;
  }

  @computed
  get getLeadingSuit(): TLeadingSuit {
    return this.controller.currentLeadingSuit;
  }
  @computed
  get getPlayedCards(): IHistoryCard[] {
    return this.controller.currentTrickCardsWithOutJoker;
  }
  @computed
  get bothCardsPlayed(): boolean {
    return this.getPlayedCards.length >= 2;
  }
  @computed
  get jokerCardPlayed(): IHistoryCard | null {
    return (
      this.controller.currentTrickCards.find(
        (c) => c.uuid === this.controller.getOwner.getUUID && c.v === "joker"
      ) ?? null
    );
  }

  @observable
  protected playedCardsUpdateWatcher?: RealtimeSubscription;

  @computed
  get ownerPlayedCard(): IHistoryCard | null {
    const card = this.getPlayedCards.find(
      (c) => c.uuid === this.controller.getOwner.getUUID
    );
    return card ? card : null;
  }
  @computed
  get opponentPlayedCard(): IHistoryCard | null {
    const card = this.getPlayedCards.find(
      (c) => c.uuid === this.controller.getOpponent.getUUID
    );
    return card ? card : null;
  }

  @observable
  pickAbilityActive: boolean = false;
  @computed
  get getPickAbilityState() {
    return this.pickAbilityActive;
  }
  @action
  setPickAbilityState = (state: boolean) => {
    this.pickAbilityActive = state;
  };
  @observable
  jokerCardsPicked: boolean = false;
  @computed
  get jokerCardPickStatus(): boolean {
    return this.jokerCardsPicked;
  }
  @observable
  jokerRandomCards: ICard[] = [];

  //FIXME: REMOVE STILL EXIST IN ORDER THAT WE SWAP OR DROP CARD
  @action
  removeCardFromHand = async (card: ICard) => {
    const handCardsWithOutPlayedCard =
      this.controller.getOwner.getHandCards.filter((c) => c.id !== card.id);
    await api.match.users.updateHandCards(
      this.controller.getOwner.getUUID,
      handCardsWithOutPlayedCard
    );
  };
  @action
  removeFromDeck = async (card: ICard) => {
    const decksWithOutSelected = this.controller.getOwner.getDeckCards.filter(
      (c) => c.id !== card.id
    );
    await api.match.users.updateDeckCards(
      this.controller.getOwner.getUUID,
      decksWithOutSelected
    );
  };

  @action
  setHistoryMoveCard = async (card: ICard) => {
    if (this.getPlayedCards.length >= 2) return;
    const usedCard = this.createCardDTOWithCurrentUserID(card);
    await this.controller.storeHistoryMove(usedCard);
  };

  @action
  playRegularCard = async (card: ICard) => {
    if (this.handlingAbilityRequest) return;
    this.handlingAbilityRequest = true;
    this.usedCard = card;
    await this.setHistoryMoveCard(card);
    const isAbilityUsed = await this.checkIfCardHasAbility(card.mark);
    if (!isAbilityUsed)
      await this.controller.handleTurnChange(
        [...this.controller.currentTrickCardsWithOutJoker],
        card
      );
    this.handlingAbilityRequest = false;
  };

  @action
  invokeJokerAbilityModal = () => {
    let jokerCards: ICard[] = [];
    jokerCards = this.controller.getOwner.getDeckCards.slice(0, 3);
    this.jokerRandomCards = jokerCards;
    this.handlingAbilityRequest = false;
  };
  @action
  useJackAbility = async (card: ICard) => {
    if (this.handlingAbilityRequest) return;
    this.handlingAbilityRequest = true;
    const ownerDeck = [...this.controller.getOwner.getDeckCards];
    const randomCard = ownerDeck[Math.floor(Math.random() * ownerDeck.length)];
    const deckCardsWithOutSelected = ownerDeck.filter(
      (c) => c.id !== randomCard.id
    );
    const deckCardsWithDroppedCard = deckCardsWithOutSelected.concat(card);
    const handCardsWithOutSelected = [
      ...this.controller.getOwner.getHandCards,
    ].filter((c) => c.id !== card.id);
    const handCardsWithRandomCard = handCardsWithOutSelected.concat(randomCard);

    await api.match.users.updateCards(
      this.controller.getOwner.getUUID,
      handCardsWithRandomCard,
      deckCardsWithDroppedCard
    );

    await this.controller.handleTurnChange([
      ...this.controller.currentTrickCardsWithOutJoker,
    ]);
    runInAction(() => {
      this.pickAbilityActive = false;
      this.handlingAbilityRequest = false;
    });
  };

  @action
  useQueenAbility = async (card: ICard) => {
    if (this.handlingAbilityRequest) return;
    this.handlingAbilityRequest = true;
    this.pickAbilityActive = false;
    const opponentRandomCard =
      this.controller.getOpponent.getHandCards[
        Math.floor(
          Math.random() * this.controller.getOpponent.getHandCards.length
        )
      ];

    await this.applyQueenAbility(card, opponentRandomCard);
  };

  @action
  protected applyQueenAbility = async (
    ownerCard: ICard,
    opponentCard: ICard
  ): Promise<void> => {
    const opponentHandCardsWithoutRandom = [
      ...this.controller.getOpponent.getHandCards,
    ].filter((c) => c.id !== opponentCard.id);
    const opponentHandCardsWithOwnerCard =
      opponentHandCardsWithoutRandom.concat(ownerCard);

    const ownerHandCardsWithoutSelected = [
      ...this.controller.getOwner.getHandCards,
    ].filter((c) => c.id !== ownerCard.id);
    const ownerHandCardsWithOpponentCard =
      ownerHandCardsWithoutSelected.concat(opponentCard);

    await api.match.users.updateHandCards(
      this.controller.getOwner.getUUID,
      ownerHandCardsWithOpponentCard
    );
    await api.match.users.updateHandCards(
      this.controller.getOpponent.getUUID,
      opponentHandCardsWithOwnerCard
    );
    await this.controller.handleTurnChange(
      this.controller.currentTrickCardsWithOutJoker
    );
    runInAction(() => {
      this.pickAbilityActive = false;
      this.handlingAbilityRequest = false;
    });
  };

  @action
  useJokerAbility = async (card: ICard) => {
    this.jokerRandomCards = [];
    await this.removeFromDeck(card);
    await this.setHistoryMoveCard(card);
    const isAbilityUsed = await this.checkIfCardHasAbility(card.mark);
    if (!isAbilityUsed)
      await this.controller.handleTurnChange(
        [...this.controller.currentTrickCardsWithOutJoker],
        card
      );
  };

  @action
  stashJokerCard = async (card: ICard) => {
    if (this.ownerPlayedCard || this.handlingAbilityRequest) return;
    this.handlingAbilityRequest = true;
    const newDeckCards = this.controller.getOwner.getDeckCards.filter(
      (c) => c.id !== card.id
    );
    const newHandCards = this.controller.getOwner.getHandCards.concat(card);

    await api.match.users.updateCards(
      this.controller.getOwner.getUUID,
      newHandCards,
      newDeckCards
    );
    runInAction(() => {
      this.jokerRandomCards = [];
      this.jokerCardsPicked = false;
      this.handlingAbilityRequest = false;
      this.usedCard = undefined;
    });
  };

  @action
  cancelJokerAbility = async () => {
    if (this.isHandlingAbilityRequest) return;
    if (this.jokerCardPlayed) {
      this.handlingAbilityRequest = true;
      const newHandCards = this.controller.getOwner.getHandCards.concat({
        mark: this.jokerCardPlayed.v,
        suit: this.jokerCardPlayed.s,
        image: this.jokerCardPlayed.image,
        id: this.jokerCardPlayed.id,
        type: `${this.jokerCardPlayed.s}-${this.jokerCardPlayed.v}`,
        weight: 0,
      });

      await this.controller.removeHistoryCard(this.jokerCardPlayed);

      await api.match.users.updateHandCards(
        this.controller.getOwner.getUUID,
        newHandCards
      );

      runInAction(() => {
        this.handlingAbilityRequest = false;
        this.jokerCardsPicked = false;
        this.jokerRandomCards = [];
        this.usedCard = undefined;
      });
    }
  };

  createCardDTOWithCurrentUserID = (card: ICard) => {
    return {
      user_uuid: this.controller.getOwner.getUUID,
      ...card,
    };
  };

  @action
  clearIndicators = async () => {
    runInAction(() => {
      this.handlingAbilityRequest = false;
      this.pickAbilityActive = false;
      this.jokerCardsPicked = false;
      this.jokerRandomCards = [];
    });
  };

  @action
  checkIfCardHasAbility = (value: TCardValue) => {
    if (value === "joker") {
      this.invokeJokerAbilityModal();
      return true;
    }

    if (this.controller.getOwner.getHandCards.length > 1) {
      if (value === "jack" || value === "queen") {
        this.pickAbilityActive = true;
        return true;
      }
    }
    return false;
  };
  protected _sync = () => {
    if (
      this.ownerPlayedCard &&
      this.checkIfCardHasAbility(this.ownerPlayedCard.v)
    )
      this.setPickAbilityState(true);
  };
}
