import React from "react";
import Button from "../Button";
import { useEthers } from "@usedapp/core";

interface IProps extends React.HTMLProps<HTMLButtonElement> {
  text?: string;
}

const ConnectWallet: React.FC<IProps> = ({
  text = "Connect Wallet",
  children,
  className,
  ...rest
}) => {
  const { activateBrowserWallet } = useEthers();

  return <Button onClick={activateBrowserWallet}>{text}</Button>;
};

export default ConnectWallet;
