import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { useMatchVM } from "../../../../../../MatchVM";
import { observer } from "mobx-react";

interface IProps extends React.HTMLProps<HTMLDivElement> {}

const RoundTitle: React.FC<IProps> = observer(({ ...rest }) => {
  const { controller } = useMatchVM();
  const { currentRoundNumber, isOwnerTurn } = controller;
  return (
    <div className={cn(style.root, rest.className)} {...rest}>
      {currentRoundNumber < 4 ? (
        <React.Fragment>
          <b className={cn("text title bold")}>Round {currentRoundNumber}:</b>
          <span className={cn("text title")}>
            {isOwnerTurn
              ? `Your Turn ${
                  !controller.cardsModel.getLeadingSuit
                    ? "(select a card and suit to lead with)"
                    : ""
                }`
              : "Opponent's Turn"}
          </span>
        </React.Fragment>
      ) : (
        <b className={cn("text title bold")}>Game Over</b>
      )}
    </div>
  );
});

export default RoundTitle;
