import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import { observer } from "mobx-react";
import { useMatchVM } from "../../../../../../MatchVM";

interface IProps extends React.HTMLProps<HTMLTableElement> {}

const ScoreTable: React.FC<IProps> = observer(({ ...rest }) => {
  const { controller } = useMatchVM();
  const { opponentPointsScore, ownerPointsScore } = controller;
  const getTotal = (points: number[]) =>
    points.reduce((acc: number, value: number) => acc + value, 0);

  return (
    <table className={cn(style.root, rest.className)} {...rest}>
      <tbody>
        <tr>
          <td className={"text default"}>{opponentPointsScore[0] ?? 0}</td>
          <td className={"text default"}>{opponentPointsScore[1] ?? 0}</td>
          <td className={"text default"}>{opponentPointsScore[2] ?? 0}</td>
          <td className={"text default"}>{getTotal(opponentPointsScore)}</td>
        </tr>
        <tr>
          <td className={"text default"}>{ownerPointsScore[0] ?? 0}</td>
          <td className={"text default"}>{ownerPointsScore[1] ?? 0}</td>
          <td className={"text default"}>{ownerPointsScore[2] ?? 0}</td>
          <td className={"text default"}>{getTotal(ownerPointsScore)}</td>
        </tr>
      </tbody>
    </table>
  );
});

export default ScoreTable;
