import React from "react";
import Modal from "../../../../../components/Modal";
import { ICard } from "../../../../../services/api/match";
import Card from "../../../../../components/OwnerCard";
import style from "./style.module.scss";
import cn from "classnames";
import Button from "../../../../../components/Button";
import { useMatchVM } from "../../../MatchVM";

interface IProps {
  cards: ICard[];
}

const JokerAbility: React.FC<IProps> = ({ cards }) => {
  const { controller } = useMatchVM();
  const { cardsModel, getOwner } = controller;

  //FIXME: Make helper for this function
  const isCardCanBePlayed = (card: ICard) => {
    //FIXME: What it stands for?
    const anyLeadingSuitCards = !getOwner.getHandCards.find(
      (c) => c.suit === controller.cardsModel.getLeadingSuit
    );
    const isCardInLeadingSuit =
      card.suit === controller.cardsModel.getLeadingSuit ||
      card.suit === "spades" ||
      card.mark === "joker";
    return isCardInLeadingSuit || anyLeadingSuitCards;
  };
  return (
    <Modal
      modalTitle={<h1>Joker Play</h1>}
      onClose={cardsModel.cancelJokerAbility}
    >
      <div className={cn(style.root)}>
        <h3>
          Select a card to either play immediately or stash into your hand.
        </h3>
        <div className={cn(style.cards)}>
          {cards.map((card, index) => (
            <Card card={card} key={index} className={cn(style.card)}>
              {isCardCanBePlayed(card) && (
                <Button onClick={() => cardsModel.useJokerAbility(card)}>
                  Play card
                </Button>
              )}
              <Button onClick={() => cardsModel.stashJokerCard(card)}>
                Stash Card
              </Button>
            </Card>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default JokerAbility;
