import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import CardsStack from "./components/CardsStack";
import CardsAmount from "./components/CardsAmount";
import { ICard } from "../../../../../../services/api/match";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  cards: ICard[] | number;
  isOpponent?: boolean;
}

const RemainingCards: React.FC<IProps> = ({ cards, isOpponent }) => {
  return (
    <div className={cn(style.root, { opponent: isOpponent })}>
      <CardsAmount amount={Array.isArray(cards) ? cards.length : cards} />
      <CardsStack cards={cards} isOpponent={isOpponent} />
    </div>
  );
};

export default RemainingCards;
