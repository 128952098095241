import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import closeIcon from "../../assets/svg/close.svg";

interface IProps extends React.HTMLProps<HTMLDivElement> {
  modalTitle: React.ReactChild;
  onClose: () => void;
  closeOnBgClick?: boolean;
  children?: React.ReactElement;
}

const Modal: React.FC<IProps> = ({
  modalTitle,
  onClose,
  closeOnBgClick = true,
  children,
  ...rest
}) => {
  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  const handleClose = () => {
    if (onClose) onClose();
  };
  return (
    <div
      id={modalTitle.toString()}
      onClick={() => closeOnBgClick && handleClose()}
      className={cn(style.root)}
    >
      <div className={cn(style.modalContainer)}>
        <div className={cn("text regular", style.modalHeading)}>
          <span>{modalTitle}</span>
          <img
            className={cn(style.modalClose)}
            src={closeIcon}
            alt={"Close"}
            onClick={handleClose}
          />
        </div>
        <div
          onClick={handleStopPropagation}
          className={cn(style.modalContent)}
          {...rest}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
