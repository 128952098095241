import { ChainId, useEthers } from "@usedapp/core";
import { ethers } from "ethers";

export const getNetworkCurrency = (chainID: number | undefined): string => {
  switch (chainID) {
    case ChainId.Localhost:
    case ChainId.Moonriver:
    case ChainId.MoonbaseAlpha:
      return "MOVR";
    case ChainId.Mainnet:
      return ethers.constants.EtherSymbol;
    default:
      return "MOVR";
  }
};

const useCurrency = (): string => {
  const { chainId } = useEthers();
  return getNetworkCurrency(chainId);
};

export default useCurrency;
