import React from "react";
import cn from "classnames";
import style from "./style.module.scss";
import vs from "../../../../assets/svg/vs.svg";
import cardPlaceholder from "../../../../assets/svg/card-drop.svg";
import { observer } from "mobx-react";
import { useLobbiesVM } from "../../LobbiesVM";
import { ISession } from "../../../../services/api/lobby";
import Button from "../../../../components/Button";
import { etc } from "../../../../utils/helpers";
import SelectedDeck from "../SelectedDeck";

interface IProps {
  session: ISession;
}

const ActiveSession: React.FC<IProps> = observer(({ session }) => {
  const {
    getExistingMatchId,
    reconnectToMatch,
    getOwnerSelectedDeckID,
    getOpponentSelectedDeckID,
  } = useLobbiesVM();
  return (
    <div className={cn(style.root, "text regular")}>
      <div className={cn(style.playerBlock)}>
        {getOwnerSelectedDeckID ? (
          <SelectedDeck deckID={getOwnerSelectedDeckID} />
        ) : (
          <img
            className={cn(style.playerPlaceholder)}
            src={cardPlaceholder}
            alt=""
          />
        )}
        <span>{etc.truncateAccount(session.owner_hash)}</span>
      </div>
      <div className={cn(style.vsContainer)}>
        <img className={cn(style.vs)} src={vs} alt="" />
        {getExistingMatchId && session.status === "ready_to_launch" ? (
          <Button onClick={reconnectToMatch}>Reconnect</Button>
        ) : session.status === "players_in_session" ? (
          <div className={cn(style.status)}>Preparing battle field!</div>
        ) : (
          <div className={cn(style.status)}>Waiting for opponent...</div>
        )}
      </div>
      <div className={cn(style.playerBlock)}>
        {getOpponentSelectedDeckID ? (
          <SelectedDeck deckID={getOpponentSelectedDeckID} />
        ) : (
          <img
            className={cn(style.playerPlaceholder)}
            src={cardPlaceholder}
            alt=""
          />
        )}
        <span>
          {session.opponent_hash && etc.truncateAccount(session.opponent_hash)}
        </span>
      </div>
    </div>
  );
});

export default ActiveSession;
