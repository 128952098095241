import React, { useEffect } from "react";
import style from "./style.module.scss";
import cn from "classnames";
import { etc } from "../../../../utils/helpers";
import { useAwakenedChampionTokenURI } from "../../../../hooks/useDeck";
import cardPlaceholder from "../../../../assets/svg/card-drop.svg";

interface IProps {
  deckID: number;
}

const SelectedDeck: React.FC<IProps> = ({ deckID }) => {
  const [cardBack, setCardBack] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const deckURI = useAwakenedChampionTokenURI(deckID);
  useEffect(() => {
    if (!deckURI.length || deckURI.length === 0) return;
    const fetchCardBack = async () => {
      const resp = await fetch(deckURI);
      const dto = await resp.json();
      const cardBack = etc.replaceIPFSURI(dto.ui.assets.deck?.back) ?? null;
      setCardBack(cardBack);
      setLoading(false);
    };

    fetchCardBack().catch(() => {
      setCardBack(cardPlaceholder);
      setLoading(false);
    });
  }, [deckURI]);

  return (
    <div key={deckID} className={cn(style.root)}>
      {loading && "Loading..."}
      {cardBack && (
        <img
          src={cardBack}
          alt={"Selected Deck PFP"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = cardPlaceholder;
          }}
        />
      )}
    </div>
  );
};

export default SelectedDeck;
